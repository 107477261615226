import ButtonWithImage from "../../../../components/ButtonWithImage/ButtonWithImage.component";
import React, {useState} from "react";
import {Button, Image} from "semantic-ui-react";
import {LoadingBlock} from "../../../ui/Loading";

export function GenerateTermSheetButton() {
  const [loading, setLoading] = useState<boolean>();

    const handleDownload = () => {
        const hash = Math.random().toString(36).substr(2, 8);
        // Define a minimal PDF content as a string
        const pdfContent = `%PDF-1.4
1 0 obj
<< /Type /Catalog /Pages 2 0 R >>
endobj
2 0 obj
<< /Type /Pages /Kids [3 0 R] /Count 1 >>
endobj
3 0 obj
<< /Type /Page /Parent 2 0 R /MediaBox [0 0 612 792] /Contents 4 0 R >>
endobj
4 0 obj
<< /Length 55 >>
stream
BT
/F1 24 Tf
100 700 Td
(Dummy PDF) Tj
ET
endstream
endobj
xref
0 5
0000000000 65535 f 
0000000010 00000 n 
0000000053 00000 n 
0000000100 00000 n 
0000000178 00000 n 
trailer
<< /Size 5 /Root 1 0 R >>
startxref
256
%%EOF`;
        // Create a Blob with the PDF content and the proper MIME type
        const blob = new Blob([pdfContent], { type: 'application/pdf' });
        // Create an object URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = `${hash}-Term-Sheet.pdf`;
        document.body.appendChild(link);
        link.click();
        // Cleanup: remove the link and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
  return <>
      <Button floated="right"
                 style={{
                   // position: "relative",
                   // top: -10,
                   // backgroundColor: "white",
                   border: "1px solid #ccc"}}
                 loading={loading}
                 onClick={()=>{
                     setLoading(true);
                     setTimeout(()=>{
                         handleDownload();
                         setLoading(false);
                     }, 2000)
                 }}
  >
    <div
        // style={{float: "left", marginTop: 12}}
    >Generate Indicative Term Sheet</div>
    {/*<Image floated="right" src="/logos/demo/turnkey-lender.png" style={{ width:'90px', borderRadius: '2px'}} />*/}
  </Button></>
}