import {useHistory} from "react-router-dom";
import {PS_URLS, useCompanyId} from "../../core";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {deleteWhatIfScenario, createWhatIfScenario, listWhatIfScenarios} from "./whatIfScenarios.client";
import {getAmProjects, getDefaultProjectVersions} from "../projects/amProject.client";
import {CreateWhatIfScenarioRequest, ListWhatIfScenariosResponse} from "../../ps-types";
import {format} from "date-fns";
import { DeleteButton } from "../../ui/crud/DeleteButton.component";


function WhatIfScenarioLink({scenario}: {scenario: ListWhatIfScenariosResponse[0]}) {
   return <a href={PS_URLS(scenario.companyId).whatIfScenario(scenario.scenarioId)}>{scenario.scenarioName}</a>
}

export function WhatIfScenariosPage() {

  const history = useHistory();

  const companyId = useCompanyId();
  const getEntities = () =>  listWhatIfScenarios(companyId).then(res => {
    return res.map(s => ({...s, id: s.scenarioId}))
  });

  const createEntity =  async ({scenarioName, type}: {scenarioName: string, type: string}) => {
    const projects = await getAmProjects(companyId);

    const projectVersions = getDefaultProjectVersions(projects);

    const scenarioType = type as CreateWhatIfScenarioRequest['type'];

    let configurationName = "";

    switch (scenarioType) {
      case "global":
        configurationName = "global-assumptions";
        break;
      case "project-version":
        configurationName = "project-version-assumptions";
        break;
      case "clone-project-with-project-version":
        configurationName = "clone-project-with-project-version";
        break;
      case "new-projects":
        configurationName = "project-version-assumptions";
        break;
    }

    let createRequest: CreateWhatIfScenarioRequest = {
      scenarioName: scenarioName,
      type: scenarioType,
      projectVersions: projectVersions,
      configurationName,
    }

    if(type === "global") {
      createRequest.globalInputs = {}
      createRequest.projectVersions = projectVersions;
    } else if(type === "project-version") {
      createRequest.projectVersionInputs = {};
      createRequest.projectVersions = projectVersions;
    } else if(type === "clone-project-with-project-version"){
      createRequest.projectVersionInputs = {};
      createRequest.projectVersions = projectVersions;
    } else if(type === "new-projects") {
      createRequest.projectVersions = []
    }

    let scenario = await createWhatIfScenario(companyId, createRequest);

    history.push(PS_URLS(companyId).whatIfScenario(scenario.scenarioId!))
  }

  const DeleteBtn = ({
    entityName,
    entity,
    handleDelete,
  }: {
    entityName: string;
    entity: any;
    handleDelete: (entity: any) => void;
  }) =>{
    const content = `Are you sure you want to delete the ${entityName} '${entity["scenarioName"]}'?`;
    return (
      <DeleteButton content={content} onDelete={() => handleDelete(entity)} />
    );
  }

  const deleteEntity = async (scenario: any) => {
    deleteWhatIfScenario(scenario.companyId, scenario.scenarioId);
  }

  let def = buildEntityDef(
    {
      entityName: "Scenario",
      title: "What If Scenarios",
      getEntities,
      createEntity,
      deleteEntity,
      table: {
        tableComponent: 'dataGrid',
        actionColumnTitle: "DELETE",
        deleteButton: (props) => <DeleteBtn {...props} />,
      }
    },
    {
      scenarioName: {
        title: "Scenario Name",
        table: {
          render: (_value: string, scenario) => <WhatIfScenarioLink scenario={scenario} />
        }
      },
      status: {
        create: { type: "hidden" }
      },
      createdAt: {
        title: "Created At",
        table: {
          render: (value: number) =>  <>{format(value, "HH:mm:ss dd/MM/yyyy")}</>
        },
        create: { type: "hidden" }
      },
      type: {
        title: "Scenario Type",
        table: {
          render: (value: string) => <>{({'global': 'Global', 'project-version': 'Local', 'new-projects':'New Projects', 'clone-project-with-project-version': 'Clone Project With Versions'}[value])}</>
        },
        create: {
          type: "select",
          options: [
            {value: "global", text: "Global"},
            {value: "project-version", text: "Local"},
            {value: "clone-project-with-project-version", text: "Clone Project With Versions"},
            {value: "new-projects", text: "New Projects"}
          ]
        }
      }
    }
  );



  return <div>
    <EntityCrud entityDef={def} />
  </div>
}