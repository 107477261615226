import React from "react";
import { FormField, Input, Label, Dropdown, Grid } from "semantic-ui-react";
import { MapperConfigurator } from "./MapperConfigurator";
import {ExcelTimeLineMapperConfig} from "../../../ps-models/exa/model-config/ExcelTimeLineMapper";
import {ExcelSingleLineItemMapperConfig} from "../../../ps-models/exa";

export function ExcelTimeLineMapperConfigurator({ mapper, index, updateMapper }: MapperConfigurator<ExcelTimeLineMapperConfig>) {
  return (
    <div>
            <strong>Excel Time Line Config</strong>
            <FormField>
              <Label>Name</Label>
              <Input
                placeholder="Name"
                value={mapper.name}
                onChange={(e) =>
                  updateMapper(index, {
                    name: e.target.value,
                  })
                }
              />
            </FormField>
            <FormField>
              <Label>Label</Label>
              <Input
                placeholder="Label"
                value={mapper.label}
                onChange={(e) =>
                  updateMapper(index, {
                    label: e.target.value,
                  })
                }
              />
            </FormField>
      <FormField>
        <Label>Direction</Label>
        <Dropdown
          labeled
          selection
          options={[
            {key: "right", text: "Right", value: "right"},
            {key: "down", text: "Down", value: "down"},
          ]}
          value={mapper.direction}
          onChange={(e, {value}) =>
            updateMapper(index, {
              direction: value as ExcelSingleLineItemMapperConfig["direction"],
            })
          }
        />
      </FormField>
            <FormField>
              <Label>Granularity</Label>
              <Dropdown
                labeled
                selection
                options={[
                  { key: "years", text: "Years", value: "years" },
                  { key: "months", text: "Months", value: "months" },
                  { key: "days", text: "Days", value: "days" },
                ]}
                value={mapper.timeDefinition.granularity}
                onChange={(e, { value }) =>
                  updateMapper(index, {
                    timeDefinition: {
                      ...mapper.timeDefinition,
                      granularity: value as "years" | "months",
                    },
                  })
                }
              />
            </FormField>
            <FormField>
              <Label>Relative To</Label>
              <Input
                placeholder="Relative To"
                value={mapper.relativeTo}
                onChange={(e) =>
                  updateMapper(index, {
                    relativeTo: e.target.value,
                  })
                }
              />
            </FormField>
    </div>
  );
}

export default ExcelTimeLineMapperConfigurator;