import {FormField, Input, Label} from "semantic-ui-react";
import React from "react";
import {MapperConfigurator} from "./MapperConfigurator";
import {ExcelParameterLineItemConfig} from "../../../ps-models/exa";

export function ParameterMapperConfigurator({mapper, index, updateMapper}: MapperConfigurator<ExcelParameterLineItemConfig>) {
  return <div>
    <FormField>
      <Label>Name</Label>
      <Input
        placeholder="Label"
        value={mapper.name}
        onChange={(e) =>
          updateMapper(index, {name: e.target.value})
        }
      />
    </FormField>
    <FormField>
      <Label>Label</Label>
      <Input
        placeholder="Label"
        value={mapper.label}
        onChange={(e) =>
          updateMapper(index, {label: e.target.value})
        }
      />
    </FormField>
  </div>
}