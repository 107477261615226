import {AggregateOver, StoreQuery} from "../../../ps-models/lineitems-store";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import React from "react";
import {Button, Grid, Input, Message, Popup, Select} from "semantic-ui-react";
import {Metric, METRIC_COMP_SIZE, MetricProps} from "../../../statistics/Metric";
import {AggregatorMethod, formattedValueAsNumber, getAmProjectConfig, inferValueType} from "../../../ps-models";
import {
  getConfig, getGlobalContext,
  getStore,
  useOnConfigChange,
  useOnStoreReady,
  useUpdateOnGlobalContextChange,
} from "./commons";
import {DateRangeType} from "../../../ps-types";
import {DefaultAggregateOverConfigurator} from "../common-components/DefaultAggregateOverConfigurator";
import {useCompany} from "../../../core";


interface ConfigProps {
    title: MetricProps['label'];
    size: typeof METRIC_COMP_SIZE[number];
    lineItemName: string | null;
    tooltipContent?: string;
    displayLabelWithoutUpperCasing: MetricProps['displayLabelWithoutUpperCasing'];
    aggregationMethod: AggregatorMethod;
    defaultAggregateOver: AggregateOver;
}

registerWidgetType({
    typeId: 'Metric',
    metadata: {
      name: 'Relevant Metrics',
      description: '',
      icon: 'star',
    },
    defaultConfig: {
        size: "small",
        title: "",
        lineItemName: null,
        aggregationMethod: "last",
        defaultAggregateOver: AggregateOver.TIME_INDEX,
    } as ConfigProps,
    renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
      return <WidgetConfig
        config={config} context={context} onConfigChange={setConfig}/>
    },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <WidgetWithComparison config={config.config} context={context} setOutput={setOutput} />
    }
  }
)
const LIMITED_AGGREGATION_METHOD_OPTIONS= ['sum', 'first', 'last', 'avg'].map((it)=>({key:it, text: it, value: it, label: it}));
const METRIC_SIZE_OPTIONS = METRIC_COMP_SIZE.map((size)=>({key: size, text: size, value: size, label: size}))
const COMPONENT_NEEDS_CONFIG_MSG = `Please make sure you specify a Line Item Name in the configuration of this widget, to be able to use it and
                make sure the line item is also selected using the aggregation builder.`

function WidgetConfig({config, context, onConfigChange}:
                        { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {

    let [localConfig, setLocalConfig] = React.useState(config);

    const handleApply = () => {
        onConfigChange(localConfig);
    }

    return <>
        <br/>
        Title: <Input value={localConfig.title} onChange={(e, data) => {
        setLocalConfig({...localConfig, title: data.value})
        }} />
        <br/>
        Line Item Name: <Input value={localConfig.lineItemName} onChange={(e, data) => {
        setLocalConfig({...localConfig, lineItemName: data.value})
        }} />
        <br/>
        Aggregation Technique: <Select
        value={localConfig.aggregationMethod}
        options={LIMITED_AGGREGATION_METHOD_OPTIONS}
        onChange={(e, data) => {
            setLocalConfig({...localConfig, aggregationMethod: data.value as AggregatorMethod})
        }}
    />
        <br/>
        Size: <Select
            value={localConfig.size}
            options={METRIC_SIZE_OPTIONS}
            onChange={(e, data) => {
                setLocalConfig({...localConfig, size: data.value as typeof METRIC_COMP_SIZE[number]})
            }}
        />
        <br/>
        Tooltip: <Input value={localConfig.tooltipContent} onChange={(e, data) => {
            setLocalConfig({...localConfig, tooltipContent: data.value})
        }} />
        <br/>
        <DefaultAggregateOverConfigurator defaultAggregateOverConfig={localConfig?.defaultAggregateOver ?? AggregateOver.TIME_INDEX}
          onDefaultAggregateOverConfigChange={(updatedDefaultAggregateOver)=>{
              setLocalConfig((prev)=>({...prev, defaultAggregateOver: updatedDefaultAggregateOver }))
          }}
        />
        <Button onClick={handleApply}>Apply</Button>
    </>

}

function WidgetWithComparison({config, context, setOutput}: { config: ConfigProps,context: BuilderContext,
  setOutput: (key: string, value: any) => void }) {
    const company = useCompany();
    const { namespace } = getAmProjectConfig(company);
    let amNamespace = namespace;

  useOnStoreReady(context);
  useUpdateOnGlobalContextChange(context);


  const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
  const mainStore = context.appContext.getStore();
  if(storeToCompareWith){
      if(amNamespace === "Twynam") {
          return <>
              <Grid.Row columns={2}>
                  <Grid.Column>
                      <Widget config={config} context={{...context, store: mainStore}} setOutput={setOutput}/>
                  </Grid.Column>
                  <Grid.Column floated={"right"} style={{border: '2px dotted #FF9800'}}>
                      <Widget config={config} context={{...context, store: storeToCompareWith, isRenderingComparisonStore: true}} setOutput={setOutput}/>
                  </Grid.Column>
              </Grid.Row>
          </>
      } else {
          return <Grid style={{width: '100%'}}>
              <Grid.Row columns={2}>
                  <Grid.Column>
                      <Widget config={config} context={{...context, store: mainStore}} setOutput={setOutput}/>
                  </Grid.Column>
                  <Grid.Column style={{border: '2px dotted #FF9800'}}>
                      <Widget config={config} context={{...context, store: storeToCompareWith, isRenderingComparisonStore: true}} setOutput={setOutput}/>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
      }
  }
  return <Widget config={config} context={{...context, store: mainStore}} setOutput={setOutput}/>
}

function Widget({config, context, setOutput}: { config: ConfigProps, context: BuilderContext,
  setOutput: (key: string, value: any) => void }) {
  const company = useCompany();
  const { namespace } = getAmProjectConfig(company);
  let amNamespace = namespace;

  let store = getStore(context);
  let globalContext = getGlobalContext(context)

  let from = store.timeIndex.startDate;
  let to = store.timeIndex.endDate;

  let query = StoreQuery.all() //filter by line items

  if(globalContext.granularity) {
        query = query
            .withTimeIndex(
                store.timeIndex
                    .withGranularity(globalContext.granularity)
            );
  }

  if(globalContext.utcDateRange) {
      const dateRange: DateRangeType =  globalContext.utcDateRange;
      from =  dateRange.from || from
      to = dateRange.to || to
  }

  if(!config.lineItemName){
        if(context.readOnly){
            return <></>
        } else {
            return <Message warning>
                {COMPONENT_NEEDS_CONFIG_MSG}
            </Message>
        }
  }

  const metricEvaluationQuery = store
    .query(query, {
      aggregatedTable: {
          defaultAggregateOver: config.defaultAggregateOver,
          currentRange: {
          start: from.getTime(), end: to.getTime()
      },
          lineItemAggregations: {lineItemMap: {[config.lineItemName]: config.aggregationMethod ?? "last"}}
      }
    });

  let val = metricEvaluationQuery.lastTimeSlotValueOf(config.lineItemName)

  const metricProps = {
      size:config.size ?? "small",
      label: config.title,
      value: metricEvaluationQuery.lastTimeSlotTextOf(config.lineItemName),
      popupContent: config.tooltipContent,
      ...((amNamespace === "Twynam" && config.title === "Portfolio Tenor" && val !==undefined)  ? {
          acceptableValue: formattedValueAsNumber(val.toString(), inferValueType(val)) < 24
      }: {}),
      ...((amNamespace === "Twynam" && config.title === "Total Financing Need" && val !==undefined)  ? {
          acceptableValue: formattedValueAsNumber(val.toString(), inferValueType(val)) < 3000000
      }: {})
  }
  setOutput('value', metricEvaluationQuery.lastTimeSlotValueOf(config.lineItemName));

  return <MetricWithPopup {...metricProps} />
}

function MetricWithPopup(props: {popupContent?: string} & MetricProps){
    const {popupContent, ...metricProps} = props;
    if(popupContent){
        return <Popup
            trigger={<div><Metric {...metricProps} /></div>}
            content={popupContent}
        />
    }
    return <BaseMetricComponent {...metricProps} />
}

function BaseMetricComponent(metricProps: MetricProps){
    return <div><Metric {...metricProps} /></div>
}