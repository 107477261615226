import React, { useState } from "react";
import {Button, TextArea, Segment, Checkbox} from "semantic-ui-react";
import { ClosableSection } from "../../ClosableSection";

interface ConfigGeneratorProps {
  onGenerateConfig: (lineItems: string[], merge: boolean) => void;
}

export const ConfigGenerator: React.FC<ConfigGeneratorProps> = ({ onGenerateConfig }) => {
  const [lineItemsText, setLineItemsText] = useState<string>("");
  const [merge, setMerge] = useState<boolean>(false);

  const handleGenerateConfig = () => {
    const lineItems = lineItemsText.split("\n").map(item => item.trim()).filter(item => item);
    onGenerateConfig(lineItems, merge);
  };

  return (
    <ClosableSection title="Config Generator" level="title-bar" opened={false}>
      <Segment>
        <Checkbox label="Merge" checked={merge} onChange={() => setMerge(!merge)} />
        <TextArea
          placeholder="Enter line items, one per line"
          value={lineItemsText}
          onChange={(e) => setLineItemsText(e.target.value)}
          style={{ width: "100%", minHeight: "150px" }}
        />
        <Button onClick={handleGenerateConfig} primary>Generate Config</Button>

      </Segment>
    </ClosableSection>
  );
};