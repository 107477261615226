import React, { useEffect, useState } from "react";
import { Button, Header, Segment, Select, Input } from "semantic-ui-react";
import { PsFileUploader } from "../../ui/FileUploader.component";
import { checkModelConfig, generateConfig, saveModelConfig, getModelConfig } from "./modelBuilder.client";
import { useCompanyId } from "../../core";
import {ModelConfigMapperConfig, ModelConfigMapperResult} from "../../ps-models/exa";
import { ModelConfigCreate } from "./ModelConfig";
import { ExcelMappingResultsView } from "./ExcelMappingResultView";
import { ClosableSection } from "../../ClosableSection";
import { LoadingBlock } from "../../ui/Loading";
import { LineItemsStore, StoreQuery } from "../../ps-models/lineitems-store";
import { TimeUnits } from "../../ps-models";
import { LineItemsTableWithFormulas } from "../../lineitems-store/LineItemsTableView";
import { ConfigGenerator } from "./ConfigGenerator";
import {mergeDeepLeft, mergeDeepRight} from "ramda";

export const ModelOnboarding: React.FC = () => {
  const companyId = useCompanyId();
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<ModelConfigMapperResult>();
  const [loading, setLoading] = useState<string[]>();
  const [store, setStore] = useState<LineItemsStore>();
  const [availableConfigs, setAvailableConfigs] = useState<Record<string, ModelConfigMapperConfig>>({});
  const [selectedConfigName, setSelectedConfigName] = useState<string | undefined>();

  useEffect(() => {
    if (result && result.store) {
      setStore(LineItemsStore.deserialize(result.store));
    }
  }, [result]);

  useEffect(() => {
    const fetchConfigs = async () => {
      const configs = await getModelConfig(companyId);
      setAvailableConfigs(configs);
    };
    fetchConfigs();
  }, [companyId]);

  const [config, setConfig] = useState<ModelConfigMapperConfig>({
    name: "Default",
    granularity: "months",
    mappers: []
  });

  const onFileUpload = async (file: File | undefined) => {
    if (!file) {
      return;
    }
    setFile(file);
  };

  const handleGenerateConfig = async (lineItems: string[], merge: boolean) => {
    try {
      setLoading([
        "Analyzing Excel Worksheets",
        "Extracting Line Item Metadata",
        "Finding best candidates for the data set",
        "Generating Configuration",
        "Checking Configuration",
        "Validating Extracted line Items",
        "Generating Data Pipeline",
        "Extracting values from Excel"
      ]);

      let res = await generateConfig(companyId, file!, lineItems);

      let newConfig = {...config, ...res};

      if(merge) {
        newConfig = mergeDeepRight(config, res);
      }

      setConfig(newConfig);

      await handleCheckConfig(newConfig);
    } finally {
      setLoading(undefined);
    }

  };

  const handleCheckConfig = async (modelConfig: any) => {
    try {
      setLoading(["Generating Report"]);
      const res = await checkModelConfig(companyId, file!, JSON.stringify(modelConfig), () => {
      });

      setResult(res);
    } finally {
      setLoading(undefined);
    }

  };

  const handleSaveConfig = async () => {
    setLoading(["Saving Config"]);
    await saveModelConfig(companyId, config);
    setLoading(undefined);
  };

  const handleConfigSelect = (e: any, { value }: any) => {
    setSelectedConfigName(value);
    setConfig(availableConfigs[value]);
  };

  if (loading) {
    return <LoadingBlock loadingMessages={loading} />;
  }

  return (
    <Segment>
      <Header as="h1">Model Onboarding</Header>
      <PsFileUploader  setFile={onFileUpload} />
      <Input
        placeholder="Model Config Name"
        value={config.name}
        onChange={(e) => setConfig({ ...config, name: e.target.value })}
      />
      <Select
        placeholder="Select Config"
        options={Object.keys(availableConfigs).map(name => ({ key: name, value: name, text: name }))}
        value={selectedConfigName}
        onChange={handleConfigSelect}
      />
      <Button onClick={() => handleCheckConfig(config)}>Check Config</Button>
      <Button floated="right" primary onClick={() => handleSaveConfig()}>Save Config</Button>
      <hr />
      <ConfigGenerator onGenerateConfig={handleGenerateConfig} />
      <ClosableSection title={"Model Config"} level='title-bar' opened={false}>
        <ModelConfigCreate config={config} setConfig={setConfig} />
      </ClosableSection>
      {result && <ClosableSection level='title-bar' title="Data Pipeline" opened={false}>
          <ExcelMappingResultsView result={result} store={store} />
      </ClosableSection>}
      {store && <StorePreview store={store} />}
    </Segment>
  );
};

function StorePreview({ store }: { store: LineItemsStore }) {
  let [granularity, setGranularity] = useState<TimeUnits>("years");

  let table = store.query(StoreQuery.all().withTimeIndex(
    store.timeIndex.withGranularity(granularity)
  ), { withMetadata: [] });

  return (
    <Segment>
      <h3>Line Items</h3>
      <Select
        placeholder='Select granularity'
        value={granularity}
        options={[
          { key: 'days', value: 'days', text: 'Day' },
          { key: 'weeks', value: 'weeks', text: 'Weeks' },
          { key: 'months', value: 'months', text: 'Month' },
          { key: 'quarters', value: 'quarters', text: 'Quarters' },
          { key: 'years', value: 'years', text: 'Year' },
        ]}
        onChange={(e, { value }) => {
          setGranularity(value as TimeUnits);
        }}
      />
      <LineItemsTableWithFormulas
        store={store}
        queryResult={table}
      />
    </Segment>
  );
}