import { RegistrationError } from "../../auth";
import { SignUpUserData } from "../../ps-models";
import { CampaignSignUpData, CampaignSignUpPayload } from "../signupViaCampaign.client";


export interface ICP4CampaignSignUpData extends CampaignSignUpData {
    orderFormAcceptedAt: number |  undefined;    
}

export class ICP4CampaignSignUpPayload extends CampaignSignUpPayload {
readonly orderFormAcceptedAt: ICP4CampaignSignUpData['orderFormAcceptedAt'];
constructor(signUpPayload: ICP4CampaignSignUpData) {
        signUpPayload.campaign = 'icp4';
        super(signUpPayload);
        this.orderFormAcceptedAt = signUpPayload.orderFormAcceptedAt;
        if(!signUpPayload.orderFormAcceptedAt) {
            throw new RegistrationError("Order form not accepted.", "custom/failed-signup-inputs")
        }
    }
    get signUpUserData(): SignUpUserData {
        return {
            isNewUser: true,
            providerId: "password",
            profile: {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                displayName: this.displayName,
                campaign: this.campaign,
                userProvidedCompanyName: this.userProvidedCompanyName,
                orderFormAcceptedAt: this.orderFormAcceptedAt,
            },
            status: this.status,
        }
    }
}
