import {Button, Dropdown, Form, Table} from "semantic-ui-react";
import React from "react";
import {MapperConfigurators} from "./configurators/MapperConfigurators";
import {buildMapperConfigData} from "./configurators/MapperConfigurator";
import {mergeDeepLeft, mergeDeepRight} from "ramda";
import {ModelConfigItem, ModelConfigMapperConfig} from "../../ps-models/exa";


export const ModelConfigCreate = ({config, setConfig}: { config: ModelConfigMapperConfig; setConfig: (conf: ModelConfigMapperConfig) => void }) => {
 const addMapper = (type: string) => {

  setConfig({ ...config, mappers: [...config.mappers, buildMapperConfigData(type)] });
};

  const updateMapper = <K extends keyof ModelConfigItem>(index: number, value: Partial<ModelConfigItem>) => {
    const updatedMappers = [...config.mappers];
    updatedMappers[index] = mergeDeepRight(updatedMappers[index],  value) as ModelConfigItem;
    setConfig({...config, mappers: updatedMappers});
  };

  const deleteMapper = (index: number) => {
    const updatedMappers = config.mappers.filter((_, i) => i !== index);
    setConfig({...config, mappers: updatedMappers});
  };

  return (
    <div style={{padding: "20px"}}>
      <Form style={{marginBottom: "10px"}}>
        <Form.Field>
          <label>Granularity</label>
          <Dropdown
            placeholder="Select Granularity"
            selection
            options={[
              {key: "years", text: "Years", value: "years"},
              {key: "months", text: "Months", value: "months"},
            ]}
            value={config.granularity}
            onChange={(e, {value}) => setConfig({...config, granularity: value as ModelConfigMapperConfig["granularity"]})}
          />
        </Form.Field>
      </Form>
        <Button size="mini" onClick={() => addMapper("parameter")} style={{marginBottom: "10px"}}>
          Add Parameter Config
        </Button>
        <Button size="mini" onClick={() => addMapper("lineItem")} style={{marginBottom: "10px"}}>
          Add LineItem Config
        </Button>
        <Button size="mini" onClick={() => addMapper("tableOfParameters")} style={{marginBottom: "10px"}}>
          Add Table of Params
        </Button>
      <Button size="mini" onClick={() => addMapper("timeLine")} style={{marginBottom: "10px"}}>
        Add Timeline
      </Button>
      <Button size="mini" onClick={() => addMapper("tableOfLineItems")} style={{marginBottom: "10px"}}>
        Add Table of Line Items
      </Button>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Mapper Type</Table.HeaderCell>

            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {config.mappers.map((mapper, index) => (
            <Table.Row key={index}>
              <Table.Cell>{mapper.mapperType}</Table.Cell>
              <Table.Cell>
                <MapperConfigurators
                  mapperType={mapper.mapperType}
                  mapper={mapper}
                  index={index}
                  updateMapper={updateMapper}
                />
              </Table.Cell>
              <Table.Cell>
                <Button color="red" onClick={() => deleteMapper(index)}>
                  Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};


