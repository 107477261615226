import psPlatformClient from "../../psPlatformClient";
import {AmProject, AmProjectVersionCreationResponse, createBulkProjectSchema, ProjectToSync} from "../../ps-types";
import {AxiosError, AxiosResponse} from "axios";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {ValueType} from "../../ps-models/line-items";


export const createAmProject = async (companyId: string) => {
  const result = await psPlatformClient<{companyId: string }, AmProject>(
    {method: 'post', url: `/am/${companyId}/project`,
    data: {companyId } })
  return result.data;
}

export const updateDefaultVersion = async (companyId: string, projectId: string, versionId: string) => {
  const result = await psPlatformClient<{companyId: string, projectId: string, versionId: string }, null>(
    {method: 'post', url: `/am/${companyId}/project/${projectId}/version/setDefault`,
    data: {companyId, projectId, versionId } })
  return result.data;
}

export const getDownloadUrl = async (companyId: string, projectId: string, versionId: string) => {
  const result = await psPlatformClient<{companyId: string, projectId: string, versionId: string }, { url: string }>(
    {method: 'get', url: `/am/${companyId}/project/${projectId}/version/${versionId}/getUrl`,
    data: {companyId, projectId, versionId } })
  return result.data;
}

export const getAmProjects = async (companyId: string): Promise<AmProject[]> => {
  const result = await psPlatformClient<{companyId: string, projectType?: string}, AmProject[]>(
    {method: 'get', url: `/am/${companyId}/project/`, data: {companyId}})
  return result.data;
}

export const getDefaultProjectVersions = (projects: AmProject[]) => {
  return projects
    ?.filter((p) => p.defaultVersion)
    .map((p) => ({projectId: p.id, versionId: p.defaultVersion! })) ?? []
}


export const getAmProject = async (companyId: string, projectId: string): Promise<AmProject> => {
  const result = await psPlatformClient<null, AmProject>(
    {method: 'get', url: `/am/${companyId}/project/${projectId}`})
  return result.data;
}

export const deleteAmProject = async (companyId: string, projectId: string) => {
  const result = await psPlatformClient<null, null>(
    {method: 'delete', url: `/am/${companyId}/project/${projectId}`})
  return result.data;
}

export const deleteAmProjectVersion = async (companyId: string, projectId: string, versionId: string) => {
  const result = await psPlatformClient<null, null>(
    {method: 'delete', url: `/am/${companyId}/project/${projectId}/version/${versionId}`})
  return result.data;
}

export const addLineItemsFromExcel = async (companyId: string, projectId: string, versionId: string, templateConfigName: string, file: File,  updateFileTransferProgress?: (percentCompleted: number)=>void) => {
  const formDataInstance = new FormData();
  formDataInstance.append("file", file);
  formDataInstance.append("templateConfigName", templateConfigName);

  const result = await psPlatformClient<FormData, { errors: string[] }>({
    method: 'post',
    url: `/am/${companyId}/project/${projectId}/version/${versionId}/add-line-items`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance,
    onUploadProgress: ((progressEvent) => {
      if(progressEvent?.total) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
      }
    })
  });
  return result.data;

}

export const createAmProjectVersion = async (companyId: string,
                                             projectId: string,
                                             versionTitle: string,
                                             versionDescription: string,
                                             excelFile: File | null,
                                             templateName: string | null,
                                             store: LineItemsStore | null,
                                             extraParams?: Record<string, ValueType>,
                                             updateFileTransferProgress?: (percentCompleted: number)=>void
) => {

  if(!excelFile && !store) throw new Error("Either excelFile or store must be provided");

  const formDataInstance = new FormData();
  formDataInstance.append("projectId", projectId);
  formDataInstance.append("companyId", companyId);
  if(excelFile) formDataInstance.append("file", excelFile);
  if(templateName) formDataInstance.append("templateName", templateName);
  formDataInstance.append("versionTitle", versionTitle);
  formDataInstance.append("versionDescription", versionDescription);
  if(store) formDataInstance.append("store", JSON.stringify(store.serialize()));
  if(extraParams) formDataInstance.append("extraParams", JSON.stringify(extraParams));

  return await AMProjectResponseWrapper<FormData, AmProjectVersionCreationResponse>(psPlatformClient<FormData, AmProjectVersionCreationResponse>({
    method: 'post',
    url: `/am/${companyId}/project/${projectId}/version/`,
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
      data: formDataInstance,
      onUploadProgress: ((progressEvent) => {
        if(progressEvent?.total) {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
          updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
        }
      })

    }));
}

export const uploadAmProjectAttachment = async (companyId: string,
  projectId: string,
  file: File,
  updateFileTransferProgress?: (percentCompleted: number)=>void
) => {

    if(!file) throw new Error("File should be provided");

    const formDataInstance = new FormData();
    formDataInstance.append("projectId", projectId);
    formDataInstance.append("companyId", companyId);
    formDataInstance.append("file", file);

    return await AMProjectResponseWrapper<FormData, AmProject>(psPlatformClient<FormData, AmProject>({
        method: 'post',
        url: `/am/${companyId}/project/${projectId}/attachment/`,
        headers: {
        'Content-Type': `multipart/form-data;`,
        },
        data: formDataInstance,
        onUploadProgress: ((progressEvent) => {
          if(progressEvent?.total) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
            updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
          }
        })
    }));
}

export const createAmProjectVersionBulk = async (companyId: string,
  versionTitle: string,
  versionDescription: string,
  excelFile: File,
  updateFileTransferProgress?: (percentCompleted: number) => void,
) => {
  const requestBody = createBulkProjectSchema.parse({
    versionTitle: versionTitle,
    versionDescription: versionDescription,
    companyId,
  });

  const formDataInstance = new FormData();
  formDataInstance.append("companyId", requestBody.companyId);
  formDataInstance.append("file", excelFile);
  formDataInstance.append("versionTitle", requestBody.versionTitle);
  formDataInstance.append("versionDescription", requestBody.versionDescription);
  return await AMProjectResponseWrapper<FormData, AmProjectVersionCreationResponse>(psPlatformClient<FormData, AmProjectVersionCreationResponse>({
    method: 'post',
    url: `/am/${companyId}/project/bulk-version/`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance,
    onUploadProgress: ((progressEvent) => {
      if (progressEvent?.total) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
      }
    })
  }));
}


export const reprocessAmProjectVersion = async (companyId: string, projectId: string, versionId: string, excelFile: File | null,
  updateFileTransferProgress?: (percentCompleted: number) => void
) => {
  const formDataInstance = new FormData();
  formDataInstance.append("companyId", companyId);
  formDataInstance.append("projectId", projectId);
  formDataInstance.append("versionId", versionId);
  if (excelFile) formDataInstance.append("file", excelFile);

  return await AMProjectResponseWrapper<FormData, AmProjectVersionCreationResponse>(psPlatformClient<FormData, AmProjectVersionCreationResponse>({
    method: 'put',
    url: `/am/${companyId}/project/${projectId}/version/${versionId}/reprocess`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance,
    onUploadProgress: ((progressEvent) => {
      if (progressEvent?.total) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        updateFileTransferProgress && updateFileTransferProgress(percentCompleted);
      }
    })
  }));
}


const AMProjectResponseWrapper = async <Req, Res>(request: Promise<AxiosResponse<Res, Req>>): Promise<Res> => {
  try {
    const result = await request;
    return result.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response && err.response.status >= 400) {
      if (err.response.data && typeof err.response.data === 'object') {
        const errResponseDescription = err.response.data?.description;
          if (errResponseDescription?.reason) {
            throw new Error(errResponseDescription?.reason);
        }
      }
    }
    throw err;
  }
}


export const checkTemplate = async (companyId: string,
                                    excelFile: File,
                                    code: string,
                                    updateFileTransferProgress: (percentCompleted: number)=>void) => {
  const formDataInstance = new FormData();
  formDataInstance.append("companyId", companyId);
  formDataInstance.append("mapperConfig", code);
  formDataInstance.append("file", excelFile);


  return psPlatformClient<FormData, any>({
    method: 'post',
    url: `/am/${companyId}/template-config-check`,
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
    data: formDataInstance,
    onUploadProgress: ((progressEvent) => {
      if(progressEvent?.total) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent?.total)
        updateFileTransferProgress(percentCompleted);
      }
    })

  });
}

