import {Button, Header, Segment} from "semantic-ui-react";
const {Grid, Card} = require("semantic-ui-react");

export function ICP4InterestRegistered() {

  return (
    <Segment style={{ 
      padding: "40px",
      background: '#FFFFFF',
      border: 'none',
      boxShadow: 'none'
    }}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div style={{ 
              marginBottom: '40px',
              borderBottom: '1px solid #E2E8F0',
              paddingBottom: '24px'
            }}>
              <Header as="h1" style={{ 
                color: '#1A202C',
                fontSize: '24px',
                fontWeight: 600,
                marginBottom: '8px'
              }}>
                Thank you. You are all done.
              </Header>
              <p style={{ 
                color: '#718096',
                fontSize: '16px',
                margin: 0
              }}>
                {/* We will let you know in case any additional information is needed within the next 24-48 hours, post your onboarding.       */}
              </p>
            </div>

            <div style={{ 
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: '24px',
              marginBottom: '32px',
              fontSize: '16px',
            }}>
              We will let you know in case any additional information is needed within the next 24-48 hours.
            </div>

            {/* <Segment style={{
              background: '#F8FAFC',
              borderRadius: '12px',
              border: '1px solid #E2E8F0',
              padding: '24px',
              marginTop: '24px'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <div>
                  <Header as="h4" style={{ 
                    margin: '0 0 4px 0',
                    color: '#2D3748',
                    fontSize: '16px',
                    fontWeight: 600
                  }}>
                    Document Status
                  </Header>
                  <p style={{ 
                    margin: '0',
                    color: '#718096',
                    fontSize: '14px'
                  }}>
                    {getStepStatus()}
                  </p>
                </div>
                <Button
                  primary
                  disabled={!canProcessDocuments}
                  onClick={() => {
                    if (proposalUpload.file && billsUpload.file) {
                      handleUpload('proposal');
                      handleUpload('bills');
                    }
                  }}
                  style={{
                    background: '#6435C9',
                    color: '#FFFFFF',
                    opacity: canProcessDocuments ? 1 : 0.6,
                    borderRadius: '8px',
                    padding: '12px 24px',
                    height: '40px',
                    fontWeight: 500
                  }}
                >
                  Process Documents
                </Button>
              </div>
            </Segment> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}