import {
  ExcelLineItemsMapperConfig, ExcelParameterLineItemConfig,
  ExcelSingleLineItemMapperConfig,
  TableOfParametersLineItemMapperConfig
} from "../../../ps-models/exa";
import {ExcelTimeLineMapperConfig} from "../../../ps-models/exa/model-config/ExcelTimeLineMapper";

export interface MapperConfigurator<T extends ExcelLineItemsMapperConfig> {
  mapperType: string,
  mapper: T
  index: number
  updateMapper: (index: number,  value: Partial<T>) => void
}

export function buildMapperConfigData(type: string) {

  let newMapper: any;
  if (type === "parameter") {
    newMapper = {
      name: "",
      mapperType: "parameter",
      label: ""} as ExcelParameterLineItemConfig
  } else if (type === "lineItem") {
    newMapper = {
      name: "",
      mapperType: "lineItem",
      timeLine: "",
      workSheet: "", label: "",
      direction: "right"
    }  as ExcelSingleLineItemMapperConfig;
  } else if (type === "tableOfParameters") {
    newMapper = {
      name: "",
      mapperType: "tableOfParameters",
      direction: "right",
      workSheet: "",
    } as TableOfParametersLineItemMapperConfig
  } else if (type == "timeLine") {
    newMapper = {
      name: "",
      mapperType: "timeLine",
      workSheet: "", label: "",
      direction: "right",
      timeDefinition: {
        granularity: "months"
      }
    } as ExcelTimeLineMapperConfig
  } else if (type == "tableOfLineItems") {
    newMapper = {
      name: "",
      mapperType: "tableOfLineItems",
      workSheet: "", label: "",
      direction: "right",
      timeDefinition: {
        granularity: "months"
      }
    } as ExcelLineItemsMapperConfig
  }

  return newMapper;
}