import { Route } from "react-router-dom";
import { NewAuthWrapper } from "../../../auth";
import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import { ICP4Onboarding } from "../../icp4/icp4Onboarding";
import { Layout } from "../../layout";

registerModule('ICP4', new class extends DefaultModule {
    overrideUIStrings() {
        return {
          "Asset Management": "SolarSense"
        }
      }
})