import {Icon, Popup, Statistic} from "semantic-ui-react";
import * as React from "react";

export const METRIC_COMP_SIZE = ["mini", "tiny" , "small" , "large"] as const

export interface MetricProps {
    label: string,
    value: string,
    hasError?: boolean,
    suffix?: string,
    alert?: string,
    mediumAlert?: string,
    size?: typeof METRIC_COMP_SIZE[number]
    displayLabelWithoutUpperCasing?: boolean;
    acceptableValue?: boolean;
}

export function Metric({label, value, hasError, suffix, alert, mediumAlert, size, displayLabelWithoutUpperCasing, acceptableValue}: MetricProps) {
    return <>
      <Statistic size={size} color={"purple"}>
        <Statistic.Label {...(displayLabelWithoutUpperCasing ? {style:{textTransform: 'none'}}: {})}>

        {alert && <Popup content={alert} trigger={<Icon name={"warning circle"} color={"red"} />} />}
          {mediumAlert && <Popup content={mediumAlert} trigger={<Icon name={"warning circle"} color={"orange"} />} />}
          {hasError && <Icon color={"red"} name={"warning circle"} />}
            {label}
        </Statistic.Label>
        <Statistic.Value style={{overflowWrap: 'anywhere'}}>
            <>
                {acceptableValue!==undefined && <div style={{color: acceptableValue ? "green":"orange"}}>{value}</div>}
                {acceptableValue === undefined && (hasError ? <div style={{color: "red"}}>{value}</div> : `${value} ${suffix ? suffix : '' }`)}
            </>
        </Statistic.Value>
      </Statistic>
    </>
}
