import {Company, OnboardingData, PrimaryIntakeData} from "../../ps-types";
import {firestore} from "firebase-admin";
import firebase from "firebase";

export interface UserData {
    displayName: string | null
    email: string
    firstName?: string
    lastName?: string
    createdAt: Date,
    lastModified?: Date
    onboardingData?: OnboardingData
    onboardingCompleted?: any
    linkedCompany?: any
    intake?: PrimaryIntakeData
    stripeOverride?: string
    stripeId?: string
    stripeLink?: string
    accountActivated?: any
    phoneNumber?: any
    checkout_sessions?: any[]
    payments?: any[]
    subscriptions?: any[]
    userGroups?: UserGroup[]
    companies?: Company[]
    require2FA?: boolean

    id: string
    userCompany?: UserCompany,
    linkedUserData?: Record<string, any>
    profile?: UserProfile,
    status?: UserStatus
}

export type AdminConfigurableUserData = Pick<UserData, 'displayName' | 'email' | 'firstName' |'lastName' | 'createdAt' | 'lastModified' | 'phoneNumber' | 'subscriptions' | 'userGroups' | 'companies' | 'require2FA' | 'id' | 'userCompany' | 'profile' | 'status'>

export function NewUserDataFromRecord(id: string, record: UserDataRecord): UserData {
    return {
        ...record,
        status: record?.status === undefined ? "enabled": record.status,
        createdAt: record.createdAt.toDate(),
        lastModified: record.lastModified?.toDate(),
        id: id,
    }
}

export interface UserDataRecord {
    displayName: string | null
    email: string
    firstName?: string
    lastName?: string
    createdAt: firestore.Timestamp,
    lastModified?: firestore.Timestamp
    onboardingData?: OnboardingData
    onboardingCompleted?: any
    linkedCompany?: any
    intake?: PrimaryIntakeData
    stripeOverride?: string
    stripeId?: string
    stripeLink?: string
    accountActivated?: any
    phoneNumber?: any
    checkout_sessions?: UserCheckoutSessionData[]
    payments?: any[]
    subscriptions?: any[]
    require2FA?: boolean

    userGroups?: UserGroup[]
    status?: UserStatus
}

// @TODO: AUTH, defer for next release, make this stricter
export type UserCheckoutSessionData = any;



export interface UserProfile {
    email: string,
    firstName: string,
    lastName: string,
    displayName: string,
    campaign?: string,
    userProvidedCompanyName?: string,
    orderFormAcceptedAt?: number,
}

export interface UserCompany extends Company {
    subscriptions: Record<string, any>[]
}

export type UserGroup = UserGroupSpv | UserGroupCompany

export const UserStatuses = ["enabled", "disabled"] as const

export type UserStatus =  typeof UserStatuses[number]

export function validateUserGroup(dto: any, exceptionClass: new(msg: string) => Error = Error): UserGroup {
    return validateUserGroupBase(dto, exceptionClass) as UserGroup
}

//+validate
interface UserGroupBase {
    id: string,
    name: string,
    kind: string,
    members: string[],
    admins: string[],
    lastModified: Date
    createdAt: Date
}

export interface UserGroupSpv extends UserGroupBase {
    kind: 'spv'
    companyId: string
}

export interface UserGroupCompany extends UserGroupBase {
    kind: 'company'
}

export type SignUpUserData = firebase.auth.AdditionalUserInfo & {
    profile: UserProfile,
    status: UserData['status']
}
/**
  * THIS IS A GENERATED SECTION, DON'T MANUALLY EDIT ANYTHING BELOW THIS POINT
  *
  **/

export function validateUserGroupBase(dto: any, exceptionClass: new(msg: string) => Error = Error): UserGroupBase {
   if (dto === null || typeof dto !== 'object' || Array.isArray(dto)) throw new exceptionClass('Expected UserGroupBase to be "object": ' + JSON.stringify(dto));
   if (typeof dto.id !== 'string') throw new exceptionClass('Expected UserGroupBase.id to be "string": ' + JSON.stringify(dto.id));
   if (typeof dto.name !== 'string') throw new exceptionClass('Expected UserGroupBase.name to be "string": ' + JSON.stringify(dto.name));
   if (typeof dto.kind !== 'string') throw new exceptionClass('Expected UserGroupBase.kind to be "string": ' + JSON.stringify(dto.kind));
   if (!Array.isArray(dto.members) || (dto.members.some((_0: any, i0: number) => typeof dto.members[i0] !== 'string'))) throw new exceptionClass('Expected UserGroupBase.members to be "string[]": ' + JSON.stringify(dto.members));
   if (!Array.isArray(dto.admins) || (dto.admins.some((_0: any, i0: number) => typeof dto.admins[i0] !== 'string'))) throw new exceptionClass('Expected UserGroupBase.admins to be "string[]": ' + JSON.stringify(dto.admins));
   dto.lastModified = new Date(dto.lastModified);
   if (dto.lastModified instanceof Date && isNaN(dto.lastModified.getTime())) throw new exceptionClass('Expected UserGroupBase.lastModified to be "Date": ' + JSON.stringify(dto.lastModified));
   dto.createdAt = new Date(dto.createdAt);
   if (dto.createdAt instanceof Date && isNaN(dto.createdAt.getTime())) throw new exceptionClass('Expected UserGroupBase.createdAt to be "Date": ' + JSON.stringify(dto.createdAt));
   return dto as UserGroupBase
}

// DON'T ADD ANY CODE BELOW THIS POINT, NOR PAST THE GENERATED SECTION HEADER ABOVE
