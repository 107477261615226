import {NavLink} from "react-router-dom";
import {DeleteButton} from "../../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../../ui/crud/EntityCrud.component";
import {Container, Icon, Segment, List, Header} from "semantic-ui-react";
import {useCompanyId} from "../../../core";
import {formatValueWithValueType} from "../../../ps-models";
import {ValueType} from "../../../ps-models/line-items";
import * as React from "react";

function FolderList() {
    // Folder data in descending order
    const folderItems = [
        '5. Projects',
        '4. CIM and Disclosures',
        '3. Financial Information',
        '2. Contracts',
        '1. Corporate Information'
    ];
    return     (<List
        divided
        style={{
            width: '100%',
            // width: '300px',
            margin: 0,
            padding: 0,
            border: '1px solid #ccc',
            borderRadius: '4px'
        }}
    >
        {folderItems.map((folder) => (
            <List.Item
                key={folder}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 16px'
                }}
            >
                <Icon
                    name="folder"
                    size="large"
                    style={{ color: '#FBC02D'}}
                />
                <List.Content style={{ fontWeight: 300 }}>
                        {folder}
                </List.Content>
            </List.Item>
        ))}
    </List>
);
}


export function DealRoom() {
  return <Segment>
      <Header>{"Deal Room"}</Header>
    <FolderList/>
  </Segment>
}

export default function FolderListOld() {
    return (
        <List divided relaxed>
            <List.Item>
                <Icon name="folder" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header>5. Projects</List.Header>
                </List.Content>
            </List.Item>

            <List.Item>
                <Icon name="folder" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header>4. CIM and Disclosures</List.Header>
                </List.Content>
            </List.Item>

            <List.Item>
                <Icon name="folder" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header>3. Financial Information</List.Header>
                </List.Content>
            </List.Item>

            <List.Item>
                <Icon name="folder" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header>2. Contracts</List.Header>
                </List.Content>
            </List.Item>

            <List.Item>
                <Icon name="folder" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header>1. Corporate Information</List.Header>
                </List.Content>
            </List.Item>
        </List>
    );
}
