import React from 'react';
import '../ESG/ESGDesign2SignUpPage.css';
import './ICP4SignUpPage.css';
import {
    Box,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import {Checkbox, Form, Image, Button as SemanticButton, Icon} from "semantic-ui-react";
import {useForm} from "../../ui/UseForm";
import {useAuthCtx} from "../../auth/AuthContextProvider";
import {CampaignSignUp} from "../signupViaCampaign.client";
import ErrorRenderer from "../../auth/ErrorRenderer";
import { ICP4CampaignSignUpData, ICP4CampaignSignUpPayload } from './ICP4Signup.client';
import {Link} from "react-router-dom";

export default function ICP4SignUpPage() {
    const {reloadAuth} = useAuthCtx()
    const { formData, handleChange, handleSubmit, submitting, error } = useForm<ICP4CampaignSignUpData>({
        initialState: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordAgain: '',
            status: "enabled",
            userProvidedCompanyName: '',
            campaign: 'icp4',
            orderFormAcceptedAt: undefined,
        },
        onSubmit: async (data) => {
            await CampaignSignUp(new ICP4CampaignSignUpPayload(data));
            reloadAuth()
        }
    })

    const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>, data: any) => {
        handleChange({ target: { name: "orderFormAcceptedAt", value: data.checked ? Date.now() : undefined } } as any);
    };

    return (
        <div id={'icp4'}>
            <div className="splitContainer">
                {/* Left Side: Gradient with Branding */}
                <div className="splitLeft">
                    <div className="branding">
                        {/* Example Logo/Text for branding */}
                        <div className="logo">
                            {/*Your Brand*/}
                            <Image src={"/logos/Perl-Logo-Icon-White-2x.png"} style={{ width: 177 }} />
                        </div>
                        <h1>SuperCharge Asset Performance</h1>
                        <p>
                        Sign up for Perl Street's SolarSense, to monitor and enhance your asset performance and value.
                        </p>
                    </div>
                </div>

                {/* Right Side: Sign-Up Form */}
                <div className="splitRight">
                    <Box
                        component="form"
                        className="formContainer"
                        onSubmit={handleSubmit}
                    >
                        <div className="formHeader">
                            <Typography variant="h2" component="h2">
                                Sign Up
                            </Typography>
                            <Typography variant="body2">
                                Get started by creating your free account now.
                            </Typography>
                        </div>

                        {/* First Name */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Last Name */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Box>

                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                label="Company Name"
                                // inputProps={{maxLength: INPUT_FIELD_MAX_CHARACTER_LIMITS.companyName}}
                                name="userProvidedCompanyName"
                                value={formData.userProvidedCompanyName}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Business Email */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                type="email"
                                label="Business Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Password */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                type="password"
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Confirm Password */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                type="password"
                                label="Confirm Password"
                                name="passwordAgain"
                                value={formData.passwordAgain}
                                onChange={handleChange}
                            />
                        </Box>

                        <div className="subscription-agreement">
                        <h3>Order Form</h3>
                        {/* <h2 className="order-form-title">Order Form</h2> */}
                        <div className="agreement-text">
                            <p className="order-form-intro">
                                This Order Form is entered into between Perl Street, Inc.("Perl Street") and ("Client") and incorporates the <a href="https://www.perlstreet.com/terms">Terms and Conditions</a> and the <a href="https://www.perlstreet.com/subscription-agreement">Subscription Agreement</a> between the parties for the use of the online platform offered by Perl Street. All cloud services will meet or exceed the standard <a href="https://www.perlstreet.com/service-level-agreement">Perl Street Service Level Agreement</a> ("SLA") and adhere to the <a href="https://www.perlstreet.com/privacy-policy">Perl Street Privacy Policy</a>, incorporated by reference unless otherwise noted.
                            </p>

                            <div className="service-fees-section">
                                <h3 className="section-title">Service Fees</h3>
                                <div className="service-fees-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Service Instance</th>
                                                <th className="amount-column">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="service-details">
                                                    <div className="service-name">SolarSense:</div>
                                                    <div className="service-features">
                                                        <div className="feature">☑ Site Performance Tracking</div>
                                                        <div className="feature">☑ Site Benefits and Valuation</div>
                                                    </div>
                                                </td>
                                                <td className="amount-column">USD 0.00</td>
                                            </tr>
                                            <tr className="total-row">
                                                <td>Total Service Charge</td>
                                                <td className="amount-column">USD 0.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Form.Field className="consent-checkbox">
                            <Checkbox
                                label="I agree to Order Form terms."
                                name="orderFormAcceptedAt"
                                checked={formData.orderFormAcceptedAt !== undefined}
                                onChange={handleCheckboxChange}
                            />
                        </Form.Field>
                    </div>
                    <SemanticButton
                        primary
                        type="submit"
                        className="raised"
                        size="huge"
                        loading={submitting}
                        disabled={submitting || !formData.orderFormAcceptedAt}
                    >
                        Request Early Access
                        <Icon name="arrow right" />
                    </SemanticButton>
                        <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                         Already have an account?{" "}
                         <Link to="/login" className="login-link">
                             Sign in here
                         </Link>
                     </div>
                    </Box>
                </div>

                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '1rem',
                }}>
                    <ErrorRenderer error={error} />
                </div>
            </div>
        </div>
    );
}