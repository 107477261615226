import {
    LineItemsStore,
    StoreQuery
} from "../../../ps-models/lineitems-store";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import MapComponent, {MapVariant} from "../../../lineitems-store/MapComponent";
import React from "react";
import {Button, Message, Select} from "semantic-ui-react";
import {
  getConfig, getGlobalContext,
  getStore,
  useOnConfigChange,
  useOnStoreReady,
  useUpdateOnGlobalContextChange,
  useUpdateOnQueryChange
} from "./commons";
import {SideBySideView} from "../../SideBySideView";

interface ConfigProps {
    variant: MapVariant
}

const MAP_VARIANT_OPTIONS= ['roadmap', 'satellite'].map((it)=>({key:it, text: it, value: it, label: it}));

registerWidgetType({
    typeId: 'Site-Map',
    metadata: {
      name: 'Map Of Sties',
      description: '',
      icon: 'map marker alternate',
    },
    defaultConfig: {
        variant: "roadmap"
    } as ConfigProps,
    renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
      return <WidgetConfig
        config={config} context={context} onConfigChange={setConfig}/>
    },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <WidgetWithCompare config={config.config} context={context} setOutput={setOutput}/>
    }
  }
)

function WidgetConfig({config, context, onConfigChange}:
                        { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {

    let [localConfig, setLocalConfig] = React.useState(config);
    const handleApply = () => {
        onConfigChange(localConfig);
    }

    return <>
        <br/>
        Variant: <Select
            value={localConfig.variant}
            options={MAP_VARIANT_OPTIONS}
            onChange={(e, data) => {
                setLocalConfig((prev)=>({...localConfig,
                    variant: data.value as MapVariant
                }))
            }}
        />
        <br/>
        <Button onClick={handleApply}>Apply</Button>
    </>

}

export function WidgetWithCompare({config, context, setOutput}: {config: ConfigProps, context: BuilderContext, setOutput:(key: string, value: any) => void }) {
    useOnStoreReady(context);
    useUpdateOnGlobalContextChange(context);

    const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
    const mainStore = context.appContext.getStore();

    if(storeToCompareWith){
        return <SideBySideView selectedStores={mainStore}
                               compareWithStores={storeToCompareWith}
                               highlightComparedWithStore={true}
                               render={(store) =>
                                   <Widget
                                       config={config}
                                       context={{...context, store}}
                                       setOutput={setOutput}/>}
        />
    }
    return <Widget
        config={config}
        context={{...context, store: mainStore}} setOutput={setOutput}/>
}
function Widget({config, context, setOutput}: { config: ConfigProps, context: BuilderContext,
  setOutput: (key: string, value: any) => void }) {
  let store = getStore(context);
  let globalContext = getGlobalContext(context)


  if(globalContext.filterQuery) {
      store = store.view(globalContext.filterQuery)
  }

  const metadataStore = store.buildSitesMetadataStore();
  if(store.getDataSet().getFieldValues('source_location_latitude').length ===0 || store.getDataSet().getFieldValues('source_location_longitude').length === 0 ){
      if(context.readOnly) {
          return <></>
      }
        return <>
            <Message warning>
            Please make sure you select location_latitude and location_longitude params from "Query Stores" section to be able to use this widget.
            </Message>
            </>
   }
  const siteList = metadataStore.query(StoreQuery.all());
  return <>
      {siteList && <MapComponent
          variant={config.variant}
          // onClickLocation={(site) => goToSiteDetailTab && goToSiteDetailTab(site)}
          result={siteList}/>}
  </>
}