import React from "react";
import { FormField, Input, Label, Dropdown, Grid } from "semantic-ui-react";
import {MapperConfigurator} from "./MapperConfigurator";
import {TableOfParametersLineItemMapperConfig} from "../../../ps-models/exa";

function TableOfParametersMapperConfigurator({ mapper, index, updateMapper }: MapperConfigurator<TableOfParametersLineItemMapperConfig>) {
  return (
    <div>

          <FormField>
            <Label>Name</Label>
            <Input
              placeholder="Name"
              value={mapper.name}
              onChange={(e) =>
                updateMapper(index, {name: e.target.value})
              }
            />
          </FormField>

            <strong>Table Config</strong>
            <FormField>
              <Label>Worksheet</Label>
              <Input
                placeholder="Worksheet"
                value={mapper.workSheet}
                onChange={(e) =>
                  updateMapper(index, {
                    workSheet: e.target.value,
                  })
                }
              />
            </FormField>
            <FormField>
              <Label>Direction</Label>
              <Dropdown
                labeled
                selection
                options={[
                  { key: "right", text: "Right", value: "right" },
                  { key: "down", text: "Down", value: "down" },
                ]}
                value={mapper.direction}
                onChange={(e, { value }) =>
                  updateMapper(index, {
                    direction: value as "right" | "down",
                  })
                }
              />
            </FormField>
            <FormField>
              <Label>Label</Label>
              <Input
                placeholder="Label"
                value={mapper.label}
                onChange={(e) =>
                  updateMapper(index, {
                    label: e.target.value,
                  })
                }
              />
            </FormField>

    </div>
  );
}

export default TableOfParametersMapperConfigurator;