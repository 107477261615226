import {MapperConfigurator} from "./MapperConfigurator";
import {Dropdown, FormField, Grid, Input, Label} from "semantic-ui-react";
import React from "react";
import {ExcelSingleLineItemMapperConfig} from "../../../ps-models/exa";

export function LineItemMapperConfigurator({mapper, index, updateMapper}: MapperConfigurator<ExcelSingleLineItemMapperConfig>) {
  return <div>
          <strong>Line Item Config</strong>
        <FormField>
          <Label>Name</Label>
          <Input
            placeholder="Label"
            value={mapper.name}
            onChange={(e) =>
              updateMapper(index, {name: e.target.value})
            }
          />
        </FormField>

          <FormField>
            <Label>Label</Label>
            <Input
              placeholder="ValueLine Label"
              value={mapper.label}
              onChange={(e) =>
                updateMapper(index, {
                  label: e.target.value,
                })
              }
            />
          </FormField>
          <FormField>
            <Label>Direction</Label>
            <Dropdown
              labeled
              selection
              options={[
                {key: "right", text: "Right", value: "right"},
                {key: "down", text: "Down", value: "down"},
              ]}
              value={mapper.direction}
              onChange={(e, {value}) =>
                updateMapper(index, {
                  direction: value as ExcelSingleLineItemMapperConfig["direction"],
                })
              }
            />
          </FormField>
          <FormField>
            <Label>Worksheet</Label>
            <Input
              placeholder="Worksheet"
              value={mapper.workSheet}
              onChange={(e) =>
                updateMapper(index, {
                workSheet: e.target.value,
              })
              }
            />
          </FormField>
    <FormField>
      <Label>Timeline</Label>
      <Input
        placeholder="Timeline"
        value={mapper.timeLine}
        onChange={(e) =>
          updateMapper(index, {
            timeLine: e.target.value,
          })
        }
      />
    </FormField>



  </div>
}