import React from "react";
import { FormField, Input, Label, Dropdown, Grid } from "semantic-ui-react";
import { MapperConfigurator } from "./MapperConfigurator";
import { TableOfLineItemsMapperConfig } from "../../../ps-models/exa";

function TableOfLineItemsMapperConfigurator({ mapper, index, updateMapper }: MapperConfigurator<TableOfLineItemsMapperConfig>) {
  return (
    <div>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <strong>Table of Line Items Config</strong>
            <FormField>
              <Label>Name</Label>
              <Input
                placeholder="Name"
                value={mapper.name}
                onChange={(e) =>
                  updateMapper(index, { name: e.target.value })
                }
              />
            </FormField>

            <FormField>
              <Label>Worksheet</Label>
              <Input
                placeholder="Worksheet"
                value={mapper.workSheet}
                onChange={(e) =>
                  updateMapper(index, { workSheet: e.target.value })
                }
              />
            </FormField>
            <FormField>
              <Label>Direction</Label>
              <Dropdown
                labeled
                selection
                options={[
                  { key: "right", text: "Right", value: "right" },
                  { key: "down", text: "Down", value: "down" },
                ]}
                value={mapper.direction}
                onChange={(e, { value }) =>
                  updateMapper(index, { direction: value as "right" | "down" })
                }
              />
            </FormField>
            <FormField>
              <Label>Label</Label>
              <Input
                placeholder="Label"
                value={mapper.label}
                onChange={(e) =>
                  updateMapper(index, { label: e.target.value })
                }
              />
            </FormField>

            <FormField>
              <Label>Granularity</Label>
              <Dropdown
                labeled
                selection
                options={[
                  { key: "years", text: "Years", value: "years" },
                  { key: "months", text: "Months", value: "months" },
                  { key: "days", text: "Days", value: "days" },
                ]}
                value={mapper.timeDefinition.granularity}
                onChange={(e, { value }) =>
                  updateMapper(index, {
                    timeDefinition: {
                      ...mapper.timeDefinition,
                      granularity: value as "years" | "months" | "days",
                    },
                  })
                }
              />
            </FormField>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default TableOfLineItemsMapperConfigurator;