import {MapperConfigurator} from "./MapperConfigurator";
import {ParameterMapperConfigurator} from "./ParameterMapperConfigurator";
import {LineItemMapperConfigurator} from "./LineItemMapperConfigurator";
import React from "react";
import TableOfParametersMapperConfigurator from "./TableOfParametersMapperConfigurator";
import ExcelTimeLineMapperConfigurator from "./ExcelTimeLineMapperConfigurator";
import TableOfLineItemsMapperConfigurator from "./TableOfLineItemsMapperConfigurator";

export function MapperConfigurators(config: MapperConfigurator<any>) {
  switch (config.mapperType) {
    case "parameter":
      return <ParameterMapperConfigurator {...config} />;
    case "lineItem":
      return <LineItemMapperConfigurator {...config} />;
    case "tableOfParameters":
      return <TableOfParametersMapperConfigurator {...config} />;
    case "timeLine":
      return <ExcelTimeLineMapperConfigurator {...config} />;
    case "tableOfLineItems":
      return <TableOfLineItemsMapperConfigurator {...config} />;
  }
  return <div>Unknown mapper type: {config.mapperType}</div>;
}