import React, { useState } from 'react';
import './ESGDesign2SignUpPage.css';
import {
    Box,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import {Container, Image} from "semantic-ui-react";
import {useForm} from "../../ui/UseForm";
import {useAuthCtx} from "../../auth/AuthContextProvider";
import {CampaignSignUpData, CampaignSignUp} from "../signupViaCampaign.client";
import {ESGCampaignSignUpPayload} from "./ESGSignup.client";
import ErrorRenderer from "../../auth/ErrorRenderer";
import {INPUT_FIELD_MAX_CHARACTER_LIMITS} from "../../../utils/globalInputConstants";

export default function ESGDesign2SignUpPage() {
    const {reloadAuth} = useAuthCtx()
    const { formData, handleChange, handleSubmit, submitting, error } = useForm<CampaignSignUpData>({
        initialState: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordAgain: '',
            status: "enabled",
            userProvidedCompanyName: '',
            campaign: 'esg'
        },
        onSubmit: async (data) => {
            await CampaignSignUp(new ESGCampaignSignUpPayload(data));
            reloadAuth()
        }
    })

    return (
        <div id={'esg2'}>
            <div className="splitContainer">
                {/* Left Side: Gradient with Branding */}
                <div className="splitLeft">
                    <div className="branding">
                        {/* Example Logo/Text for branding */}
                        <div className="logo">
                            {/*Your Brand*/}
                            <Image src={"/logos/Perl-Logo-Icon-White-2x.png"} style={{ width: 177 }} />
                        </div>
                        <h1>SuperCharge Your Impact</h1>
                        <p>
                            Sign Up for a free trial, to instantly enhance your company's Impact reporting capabilities, across portfolios.
                        </p>
                    </div>
                </div>

                {/* Right Side: Sign-Up Form */}
                <div className="splitRight">
                    <Box
                        component="form"
                        className="formContainer"
                        onSubmit={handleSubmit}
                    >
                        <div className="formHeader">
                            <Typography variant="h2" component="h2">
                                Sign Up
                            </Typography>
                            <Typography variant="body2">
                                Get started by creating your free account now.
                            </Typography>
                        </div>

                        {/* First Name */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Last Name */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Box>

                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                label="Company Name"
                                // inputProps={{maxLength: INPUT_FIELD_MAX_CHARACTER_LIMITS.companyName}}
                                name="userProvidedCompanyName"
                                value={formData.userProvidedCompanyName}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Business Email */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                type="email"
                                label="Business Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Password */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                type="password"
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Confirm Password */}
                        <Box className="field">
                            <TextField
                                fullWidth
                                required
                                type="password"
                                label="Confirm Password"
                                name="passwordAgain"
                                value={formData.passwordAgain}
                                onChange={handleChange}
                            />
                        </Box>

                        {/* Submit Button */}
                        <Box textAlign={'center'}>
                            <Button
                                type="submit"
                                variant="contained"
                                size={"large"}
                                className="submitButton"
                                disabled={submitting}
                                // loading={submitting}
                                // disabled={submitting}
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </Box>
                </div>
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '1rem',
                }}>
                    <ErrorRenderer error={error} />
                </div>
            </div>
        </div>
    );
}
