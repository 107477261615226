import {BuilderContext, registerWidgetType} from "../../builder/WidgetRegistry";
import React from "react";
import {GenerateTermSheetButton} from "./GenerateTermSheetButton";

registerWidgetType({
        typeId: 'Twynam-termsheet-btn',
        metadata: {
            name: 'Twynam Temsheet Button',
            description: '',
            icon: 'file pdf outline',
        },
        defaultConfig: {},
        renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
            return  <></>
        },
        render: (config: any, context: BuilderContext) => {
            return  <GenerateTermSheetButton />
        }
    }
)
