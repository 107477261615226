import {TimeRange, TimeUnit, TimeUnits, utcDate} from "./Time.types";
import {TimeZoneContext} from "./TimeZoneContext.types";
import {ceilDate} from "./Time.model";


export const TimeUnitValues: TimeUnits[] = ["seconds", "minutes", "hours", "days", "weeks", "months", "quarters", "years"];

export const GREATEST_GRANULARITY = TimeUnitValues[TimeUnitValues.length - 1];

export function addTime(date: Date, duration: number, unit: TimeUnits): Date {
  switch (unit) {
    case "hours":
      return addHours(date, duration);
    case "days":
      return addDays(date, duration);
    case "months":
      return addMonths(date, duration);
    case "weeks":
      return addWeeks(date, duration);
    case "quarters":
      return addQuarter(date, duration);
    case "minutes":
      return addMinutes(date, duration);
    case "years":
      return addYears(date, duration);

    default:
      throw new Error(`Unsupported time unit ${unit}`);
  }
}

export function addQuarter(date: Date, quarters: number) {
  const d = new Date(date);
  d.setUTCMonth(d.getUTCMonth() + 3 * quarters);
  return d;
}

export function addMinutes(date: Date, minutes: number) {
  const d = new Date(date);
  d.setUTCMinutes(d.getUTCMinutes() + minutes);
  return d;
}

export function addHours(date: Date, hours: number) {
  const d = new Date(date);
  d.setUTCHours(d.getUTCHours() + hours);
  return d;
}

export function addDays(date: Date, days: number) {
  const d = new Date(date);
  d.setUTCDate(d.getUTCDate() + days);
  return d;
}

export function addWeeks(date: Date, weeks: number) {
  const d = new Date(date);
  d.setUTCDate(d.getUTCDate() + 7 * weeks);
  return d;
}

export function addMonths(date: Date, months: number) {
  const d = new Date(date);
  d.setUTCMonth(d.getUTCMonth() + months);
  return d;
}

export function addYears(date: Date, years: number) {
  const d = new Date(date);
  d.setUTCFullYear(d.getUTCFullYear() + years);
  return d;
}

export function truncateTime(timeUnit: TimeUnits, date: Date) {
  switch (timeUnit) {
    case "days":
      return dayTruncateDate(date);
    case "months":
      return monthTruncateDate(date);
    case "years":
      return yearTruncateDate(date);
    case "hours":
      return hourTruncateDate(date);
    case "quarters":
      return quarterTruncateDate(date);
    case "weeks":
      return weekTruncateDate(date);
  }
  throw new Error("Invalid time unit " + timeUnit);
}

export function truncateTimeStamp(timeUnit: TimeUnits, time: number) {
  const date = new Date(time);
  return truncateTime(timeUnit, date).getTime();
}

export function yearTruncateDate(date: Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
}

export function quarterTruncateDate(date: Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), Math.floor(date.getUTCMonth() / 3) * 3, 1));
}

export function monthTruncateDate(date: Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
}

export function dayTruncateDate(date: Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

export function weekTruncateDate(date: Date){
  return dayTruncateDate(date);
}

export function hourTruncateDate(date: Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()));
}

export function buildTimeRangeFromGranularity(timeQuery: TimeUnit, sourceGranularity: TimeUnits, targetGranularity: TimeUnits, timeZoneContext?: TimeZoneContext) {
  let startDate = truncateTimeStamp(sourceGranularity, timeQuery);
  if(timeZoneContext){
    const granularityOffset = timeZoneContext?.granularityWiseAggregationOffsetMapping?.[sourceGranularity];
    if(granularityOffset !==undefined && granularityOffset !==null && !isLowerGranularity(targetGranularity, sourceGranularity)){
        startDate = addTime(new Date(startDate), granularityOffset, sourceGranularity).getTime()
    }
  }
  const range: TimeRange = {
    start: startDate,
    end: truncateTime(sourceGranularity,
      addTime(new Date(startDate), 1, targetGranularity)).getTime()
  }

  return range;
}

export function truncateTimeRangeOnBasisOfTargetGranularity(range: TimeRange, targetGranularity: TimeUnits): TimeRange{
  return {
    start: truncateTime(targetGranularity, new Date(range.start)).getTime(),
    end: ceilDate(targetGranularity, new Date(range.end)).getTime(),
  }
}
export  function * rangeMap(range: TimeRange, granularity: TimeUnits) {
  for(let t = range.start; t < range.end; t = addTime(new Date(t), 1, granularity).getTime()) {
     yield t;
  }
}

export function compareFrequency(a: TimeUnits, b: TimeUnits): number {
  const frequencyOrder: TimeUnits[] = TimeUnitValues;
  return frequencyOrder.indexOf(a) - frequencyOrder.indexOf(b)
}

export function sameGranularity(a: TimeUnits, b: TimeUnits): boolean {
  return a === b;
}

export function isHigherGranularity(a: TimeUnits, b: TimeUnits): boolean {
  return compareFrequency(a, b) > 0;
}

export function isLowerGranularity(a: TimeUnits, b: TimeUnits): boolean {
  return compareFrequency(a, b) < 0;
}
// @TODO: Deprecated in favour of getLastDateOfMonthQtrOrYearInUTC.
export function getLastDateOfPeriodInUTC(date:Date, granularity: TimeUnits){
  let dateInUTC = utcDate(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  return addTime(addTime(truncateTime(granularity, dateInUTC), 1, granularity), -1, 'days')
}

export function getLastDateOfMonthQtrOrYearInUTC(date:Date, granularity: "months" | "years" | "quarters"){
  let dateInUTC = utcDate(date.getFullYear(),date.getMonth(),date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
  return addTime(addTime(truncateTime(granularity, dateInUTC), 1, granularity), -1, 'days')
}