import psPlatformClient from "../../psPlatformClient";


export async function icp4Onboarding(companyId: string, projectId: string) {
  return (await psPlatformClient<any, any>({
    method: 'get',
    url: `/am/${companyId}/${projectId}/icp4/onboarding`,
  })).data.linkUrl as string;
}

export async function icp4GetData(companyId: string, projectId: string) {
  return (await psPlatformClient<any, any>({
    method: 'get',
    url: `/am/${companyId}/${projectId}/icp4/data`,
  })).data as any;
}

export async function icp4SyncData(companyId: string, projectId: string, collection: string): Promise<{ success: boolean }> {
  return (await psPlatformClient<any, any>({
    method: 'post',
    url: `/am/${companyId}/${projectId}/icp4/sync`,
    data: { collection }
  })).data as { success: boolean };
}

export async function checkENodeConnectionStatus(companyId: string, projectId:string): Promise<{ isLinked: boolean; userData: any | null }> {
  return (await psPlatformClient<any, any>({
    method: 'get',
    url: `/am/${companyId}/${projectId}/icp4/connection-status`,
  })).data as { isLinked: boolean; userData: any | null };
}
