import {
    AggregateOver,
  DEFAULT_SOURCE_GRID, GridColumnOverride,
  LineItemsStore, QueryResult,
  ResultOptions, ResultOptionsGrid,
  StoreQuery
} from "../../../ps-models/lineitems-store";
import {LoadingBlock} from "../../../ui/Loading";
import {Button, Checkbox, DropdownProps, Header, Input, Segment, SegmentInline} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {LineItemsTable, LineItemsTableProps} from "../../../lineitems-store/LineItemsTableView";
import {
    formattedValueAsNumber,
    getAmProjectConfig,
    inferValueType,
    normalizeString,
    storeValueTypeField
} from "../../../ps-models";
import {useCompany} from "../../../core";
import {StoreQueryFlat, StoreQueryFlatDto} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {QueryEditor} from "../StoreQueryEditor";
import {
  getGlobalContext,
  getStore,
  useOnStoreReady,
  useUpdateOnGlobalContextChange,
} from "./commons";
import {StoreFieldSelector} from "../../../lineitems-store/StoreFieldSelector";
import {SideBySideView} from "../../SideBySideView";
import {buildParameterLineItem, LineItemsFieldSet, ValueType} from "../../../ps-models/line-items";
import {DateRangeType} from "../../../ps-types";
import {OrderedArrayInput} from "../../../ui/OrderedArrayInput";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import Form from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";
import {IChangeEvent} from "@rjsf/core";
import {DefaultAggregateOverConfigurator} from "../common-components/DefaultAggregateOverConfigurator";

export interface GridWidgetConfigProps {
  title: string;
  grid: ResultOptionsGrid,
  grouping?: {
    by: string;
    expandedByDefault?: boolean;
  },
  withMetadata?: ResultOptions['withMetadata']
  storeQueryFlat?: StoreQueryFlatDto,
  columnOrdering?: string[]
}

interface DeserializedConfigProps extends Omit<GridWidgetConfigProps, 'storeQueryFlat'> {
    storeQueryFlat?: StoreQueryFlat,
}
const DEFAULT_STORE_QUERY_FLAT = new StoreQueryFlat();
registerWidgetType({
    typeId: 'Grid',
    metadata: {
      name: 'Grid of Line Item Values',
      description: '',
      icon: 'grid layout',
    },
    defaultConfig: {
      title: '',
      grid: DEFAULT_SOURCE_GRID,
      storeQueryFlat: DEFAULT_STORE_QUERY_FLAT.serialize()
    },
    renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
      return <GridWidgetConfig
        config={config} context={context} onConfigChange={setConfig}/>
    },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <WidgetWithCompare config={config.config} context={context} setOutput={setOutput}/>
  }}
)

const deserializeConfig = (config: GridWidgetConfigProps): DeserializedConfigProps  =>{
    return {...config, ...(config.storeQueryFlat ? {storeQueryFlat: StoreQueryFlat.deserialize(config?.storeQueryFlat)} :{})} as DeserializedConfigProps;
}

export function GridWidgetConfig({config, context, onConfigChange}:
                                     { config: GridWidgetConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {
    let store = context.store as LineItemsStore;
  let [localConfig, setLocalConfig] = React.useState<DeserializedConfigProps>(deserializeConfig(config));

  const handleApply = () => {
    const serializedConfig = {...localConfig, ...(localConfig.storeQueryFlat ? {storeQueryFlat: localConfig.storeQueryFlat.serialize()} : {})}
    onConfigChange(serializedConfig);
  }

    const handleDiscard = () => {
        setLocalConfig(deserializeConfig(config));
    }

    useEffect(() => {
        setLocalConfig(deserializeConfig(config));
    }, [config]);

    if (!store) {
        return <LoadingBlock/>
    }

  return <>
      <Segment>
          <SegmentInline>
              Grouped by: <Input value={localConfig.grouping?.by} onChange={(e, data) => {
            setLocalConfig({...localConfig, grouping: {...(localConfig?.grouping ?? {}), by: data.value}})
        }} />
            <Checkbox toggle checked={localConfig?.grouping?.expandedByDefault ?? false}
                      onChange={(e, data) => {
                          console.info("BEF AFT", localConfig, {...localConfig, grouping: {...(localConfig?.grouping ?? {by: 'fields.cname'}), expandedByDefault: !!data.checked}}, !!data.checked)
                          setLocalConfig({...localConfig, grouping: {...(localConfig?.grouping ?? {by: 'fields.cname'}), expandedByDefault: !!data.checked}})
                      }}
                      label={"Group Expanded By Default"} />
        </SegmentInline>
        <SegmentInline>
              Title: <Input value={localConfig.title} onChange={(e, data) => {
              setLocalConfig({...localConfig, title: data.value})
          }} />
              <QueryEditor capabilities={["names", "fields"]} store={store} query={localConfig.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT} onQueryUpdate={(updatedQuery)=>{
                  let query = updatedQuery;
                  if(localConfig.storeQueryFlat && localConfig.storeQueryFlat.timeIndex){
                      query = updatedQuery.withTimeIndex(localConfig.storeQueryFlat.timeIndex)
                  }
                  setLocalConfig({...localConfig, storeQueryFlat: query })
              }} />
              <div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}
              >Metadata Columns: <StoreFieldSelector store={store}
                                                  value={localConfig?.withMetadata}
                                                  multiple
                                                  onChange={(e:any, data:DropdownProps) => setLocalConfig({...localConfig, withMetadata: data.value as string[]}) }
              /></div>
              <div
                  style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px', margin: '10px 0px'}}
              >Column Ordering:
                  <div>
                      <OrderedArrayInput items={localConfig?.columnOrdering ?? []} onSelected={(selectedItems)=>{
                          if(JSON.stringify(localConfig?.columnOrdering) !== JSON.stringify(selectedItems)){
                              setLocalConfig({...localConfig, columnOrdering: selectedItems})
                          }
                      }}/>
                  </div>
            </div>
              <Segment>
                  <h5>Grid Configurations</h5>
                      <DefaultAggregateOverConfigurator
                          defaultAggregateOverConfig={(localConfig.grid?.defaultAggregateOver ?? DEFAULT_SOURCE_GRID.defaultAggregateOver)}
                          onDefaultAggregateOverConfigChange={(updatedDefaultAggregateOver)=>{
                          setLocalConfig((prev)=>({...prev, grid: {...(prev.grid ?? DEFAULT_SOURCE_GRID), defaultAggregateOver: updatedDefaultAggregateOver} }))
                      }} />
                  <div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>Row Dimension: <StoreFieldSelector store={store}
                                                                                                          value={(localConfig.grid ?? DEFAULT_SOURCE_GRID).rowDimension}
                                                                                                          onChange={(e:any, data:DropdownProps) =>
                                                                                                              setLocalConfig({...localConfig, grid: {...(localConfig.grid ?? DEFAULT_SOURCE_GRID), rowDimension: data.value as string}}) }
                  /></div>
                  <div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>Row Dimension Label: <Input value={localConfig.grid?.rowDimensionLabel} onChange={(e, data) => {
                      setLocalConfig({...localConfig, grid: {...(localConfig.grid ?? DEFAULT_SOURCE_GRID), rowDimensionLabel: data.value}})
                  }} /></div>
                  <div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>Column Dimension: <StoreFieldSelector store={store}
                                                                                                             value={(localConfig.grid ?? DEFAULT_SOURCE_GRID).columnDimension}
                                                                                                             onChange={(e:any, data:any) =>
                                                                                                                 setLocalConfig({...localConfig, grid: {...(localConfig.grid ?? DEFAULT_SOURCE_GRID), columnDimension: data.value as string}}) }
                  /></div>
                  <div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>
                      <Checkbox toggle checked={localConfig?.grid?.doNotAlphabeticallySortRowsByName}
                         onChange={(e, data) => {
                             setLocalConfig((prev)=>({...prev, grid: {...(prev?.grid ?? DEFAULT_SOURCE_GRID), doNotAlphabeticallySortRowsByName: !!data.checked }}))
                         }}
                         label={"Do not alphabetically sort rows by name"} />
                  </div>
                  <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'flex-start', gap: '5px',
                      border: '1px dotted gray', width:'35%',padding: '5px 5px', marginBottom: '10px'
                  }}>
                      <strong>Aggregate Row Configuration</strong>
                      <Checkbox toggle checked={localConfig?.grid?.aggregateRowConfig !==undefined}
                                onChange={(e, data) => {
                                    if(!!data.checked === false){
                                        setLocalConfig((prev) => {
                                            if(prev?.grid?.aggregateRowConfig) {
                                                let { aggregateRowConfig, ...restGridConfig } = prev?.grid ?? DEFAULT_SOURCE_GRID;
                                                return { ...prev, ...(restGridConfig !==undefined ? { grid: restGridConfig } : {} )}
                                            }
                                            return prev;
                                        })
                                    } else {
                                        setLocalConfig((prev)=>({...prev, grid: {...(prev?.grid ?? DEFAULT_SOURCE_GRID), aggregateRowConfig: {} }}))
                                    }
                                }}
                                label={"Include Row"} />
                  </div>
                  <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'flex-start', gap: '5px',
                      border: '1px dotted gray', width:'35%',padding: '5px 5px', marginBottom: '10px'
                  }}>
                      <strong>Aggregate Column Configuration</strong>
                      <Checkbox toggle checked={localConfig?.grid?.aggregateColumnConfig !==undefined}
                                onChange={(e, data) => {
                                    if(!!data.checked === false){
                                        setLocalConfig((prev) => {
                                            if(prev?.grid?.aggregateColumnConfig) {
                                                let { aggregateColumnConfig, ...restGridConfig } = prev?.grid ?? DEFAULT_SOURCE_GRID;
                                                return { ...prev, ...(restGridConfig !==undefined ? { grid: restGridConfig } : {} )}
                                            }
                                            return prev;
                                        })
                                    } else {
                                        setLocalConfig((prev)=>({...prev, grid: {...(prev?.grid ?? DEFAULT_SOURCE_GRID), aggregateColumnConfig: {} }}))
                                    }
                                }}
                                label={"Include Column"} />
                  </div>
                  <Segment>
                      <ColumnOverridesEditor definedOverrides={localConfig?.grid?.columnOverrides} onSave={(data)=>setLocalConfig({...localConfig, grid: {...(localConfig.grid ?? DEFAULT_SOURCE_GRID), ...data}})
                      } />
                  </Segment>
                  {/*  @TODO: Add a component to configure grid's lineItemsAggregationOptions */}
                  {/*<div style={{display: 'flex', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>Column Dimension: <StoreFieldSelector store={store}*/}
                  {/*                                                                                                                           value={(localConfig.grid ?? DEFAULT_SOURCE_GRID).aggregatorMap}*/}
                  {/*                                                                                                                           onChange={(e:any, data:any) =>*/}
                  {/*                                                                                                                               setLocalConfig({...localConfig, grid: {...(localConfig.grid ?? DEFAULT_SOURCE_GRID), columnDimension: data.value as string}}) }*/}
                  {/*/></div>*/}
              </Segment>
          </SegmentInline>
      </Segment>
      <Button primary onClick={handleApply}>Apply</Button>
      <Button color={"grey"} onClick={handleDiscard}>Discard</Button>
  </>

}

function WidgetWithCompare({config, context, setOutput}: {config: GridWidgetConfigProps, context: BuilderContext, setOutput:(key: string, value: any) => void }) {
    useOnStoreReady(context);
    useUpdateOnGlobalContextChange(context);

    const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
    const mainStore = context.appContext.getStore();

    if(storeToCompareWith){
        return <SideBySideView selectedStores={mainStore}
                               compareWithStores={storeToCompareWith}
                               highlightComparedWithStore={true}
                               render={(store) =>
                                   <GridWidget
                                                          config={config}
                                                          context={{...context, store}}
                                                          setOutput={setOutput}/>}
        />
    }
    return <GridWidget
        config={config}
        context={{...context, store: mainStore}} setOutput={setOutput}/>
}

export function GridWidget({config, context, setOutput, externalCustomQuery }: { config: GridWidgetConfigProps,context: BuilderContext,
  setOutput: (key: string, value: any) => void, externalCustomQuery?: StoreQuery }) {
  const company = useCompany();
  const { namespace } = getAmProjectConfig(company);
  let amNamespace = namespace;


  let originalStore = getStore(context);
  let globalContext = getGlobalContext(context)


  let store = originalStore.view(StoreQuery.all())
  let from = store.timeIndex.startDate;
  let to = store.timeIndex.endDate;

  if (!store) {
    return <LoadingBlock/>
  }

  if(globalContext.utcDateRange) {
    const dateRange: DateRangeType =  globalContext.utcDateRange;
    if(dateRange.from && dateRange.to){
        from = dateRange.from;
        to= dateRange.to;
    }
  }

  let query = StoreQuery.all()//filter by line items

  if(config.storeQueryFlat && JSON.stringify(config.storeQueryFlat) !== JSON.stringify(DEFAULT_STORE_QUERY_FLAT.serialize())) {
      query = StoreQueryFlat.deserialize(config.storeQueryFlat).toStoreQuery(true)
  }
  // This is intended to use only from other company specific widgets.
  if(externalCustomQuery){
      query = query.and(externalCustomQuery);
  }

  // @TODO: This should not be here
  if(amNamespace === 'PUBLICDEMO'){
      addWECCReportingLineItems(store, {from, to});
  }

  let result = store.query(query, {
      grid: {
        ...config.grid,
        currentRange: {start: from.getTime(), end: to.getTime()},
          // @TODO: This needs to be removed from here and made generic.
          ...(amNamespace === 'FourthPartner' ? {
        lineItemAggregations: {
            lineItemMap: {'Projected - Contract - IRR': "lastNZ", 'Actual - Contract - IRR': "lastNZ"}
        }
    }: {})
      },
       ...(config?.withMetadata ? {withMetadata: config?.withMetadata}: {}),
      // sourceQuery: store.getSourceQuery(),
      sortedNames: (Array.isArray(config.storeQueryFlat?.lineItems) && (config?.storeQueryFlat?.lineItems ?? []).length >0) ? config.storeQueryFlat?.lineItems : context.query.lineItems
  });
 let tableProps = applyResultConfigs(result, config);
    // @TODO: This should not be here
    if(amNamespace === "Twynam"){
        tableProps.columnValueChecks = {
            [normalizeString("State")]: (val: ValueType)=> {
                if(["VT", "CT", "ME"].map(normalizeString).includes(normalizeString(val.toString()))){
                    return true;
                }
                return false;
            },
            [normalizeString("End Month")]: (val: ValueType)=> {
                const valueAsNumber = formattedValueAsNumber(val.toString(), inferValueType(val));
                if(valueAsNumber<12){
                    return true;
                }
                return false;
            },
            [normalizeString("TOTAL CASH OUT")]: (val: ValueType)=> {
                const valueAsNumber = formattedValueAsNumber(val.toString(), inferValueType(val));
                if(valueAsNumber>250000 && valueAsNumber<1000000){
                    return true;
                }
                return false;
            },
        };
    }

  setOutput("queryResultData", result.data);

  return <>
    <Header as="h2" color="purple">{config.title}</Header>
    <LineItemsTable
      {...tableProps}
    />
  </>
}

function applyResultConfigs(result:QueryResult, config: GridWidgetConfigProps) {
    let tableProps: LineItemsTableProps = {
        queryResult: result,
    }
    if(config.columnOrdering){
        result.setOrderByColumnId(config.columnOrdering);
    }
    if(config?.grouping) {
        result.collapsibleBy(config?.grouping.by);
        tableProps.withGroups = true;
        if(config?.grouping.expandedByDefault){
            tableProps.isGroupExpandedByDefault = true;
        }
    }
    return tableProps;
}
function addWECCReportingLineItems(store: LineItemsStore, dateRange: DateRangeType){
    let allSiteNamesInAggregatedStore = store.getDataSet().getAggregatedStoreNames();
    for(let siteName of allSiteNamesInAggregatedStore){
        const siteLineItems = store.getDataSet().getByField('store_sourceName', siteName as string);
        const storeParamFieldNames = siteLineItems[0].fields.getAllKeys().filter((fieldName: string)=>fieldName.startsWith('source_'));
        const storeParamFieldMap = storeParamFieldNames.map((fieldName: string)=>({[fieldName]: siteLineItems[0].fields.getFieldStr(fieldName)})).reduce((a:any,b:any)=>({...a,...b}), {})

        for(let baseLiName of ['Start Date', 'End Date', 'Vintage']){
            let liName = `${siteName} - ${baseLiName}`;
            let value = baseLiName === 'Start Date' ? dateRange.from.getTime(): dateRange.to.getTime();
            const baseFieldsMap = {
                ...storeValueTypeField( baseLiName === 'Vintage' ? "utc_mmyyyy" : "utc_mmddyyyy"),
                cname:baseLiName,
                'store_sourceName': siteName,
                'store_sourceLineItemName': baseLiName,
                'store_label': liName,
                'store_sourceLabel': baseLiName,
                ...storeParamFieldMap
            };
            store.getDataSet().addLineItem(buildParameterLineItem(liName as string, value,
                LineItemsFieldSet.fromMap(baseFieldsMap)
            ));
        }
    }
    store.getDataSet().addTimedGroupingLineItemsByField('cname', {
        inheritedFields: ['subSection'],
        groupingLabel: 'store_sourceLabel',
    });
}

const columnOverridesSchema: RJSFSchema = {
    type: "object",
    properties: {
        columnOverrides: {
            type: "object",
            title: "Column Overrides",
            additionalProperties: {
                type: "object",
                properties: {
                    label: { type: "string", title: "Label" },
                    aggregateOver: { type: "string", title: "Aggregate Over", enum: Object.values(AggregateOver) }
                },
            }
        }
    }
};
const columnOverridesUiSchema: UiSchema = {
    columnOverrides: {
        additionalProperties: {
            aggregateOver: {
                "ui:widget": "radio",
            }
        }
    },
        "ui:options": {
            orderable: false,
            addable: true,
            removable: true,
        },
        "ui:submitButtonOptions": { submitText: "Finalize Override" },
};

type ColumnsOverridesFormData = {columnOverrides: GridColumnOverride} | null
function ColumnOverridesEditor({definedOverrides, onSave}: {definedOverrides?: GridColumnOverride, onSave: (columnOverrides: ColumnsOverridesFormData)=>void}){
    const [formData, setFormData] = useState<ColumnsOverridesFormData>(null);
    useEffect(()=>{
        setFormData(definedOverrides ? {columnOverrides: definedOverrides} : null)
    }, [definedOverrides])
    const onFormChange = (data: IChangeEvent<ColumnsOverridesFormData>) => {
        setFormData(data.formData ?? null);
    };

    const handleSubmit = (data: IChangeEvent<ColumnsOverridesFormData>) => {
        let hasErrors = true;
        if(data.formData?.columnOverrides && Object.keys(data.formData.columnOverrides).length>0){
            hasErrors = false;
            for (const key in data.formData.columnOverrides) {
                if (data.formData.columnOverrides.hasOwnProperty(key)) {
                    const record = data.formData.columnOverrides[key];
                    if (!record.label && record.aggregateOver === undefined) {
                        hasErrors = true;
                    }
                }
            }
        }
        if(!hasErrors){
            // @TODO: Clean this code
            const columnOverridesWithNormalizedKeys: GridColumnOverride = {};
            for (const key in formData?.columnOverrides) {
                if (formData?.columnOverrides.hasOwnProperty(key)) {
                    columnOverridesWithNormalizedKeys[normalizeString(key)] = formData?.columnOverrides[key];
                }
            }
            onSave(data.formData ? {columnOverrides: columnOverridesWithNormalizedKeys} : null);
        }
    };

    // const validate = (formData: ColumnsOverridesFormData, errors: any) => {
    //     if(formData?.columnOverrides){
    //         for (const key in formData.columnOverrides) {
    //             if (formData.columnOverrides.hasOwnProperty(key)) {
    //                 const record = formData.columnOverrides[key];
    //                 if (!record.label && record.aggregateOver === undefined) {
    //                     errors.columnOverrides[key].addError("At least one of 'Label' or 'aggregateOver' must be defined.");
    //                 } else {
    //                     delete errors.columnOverrides[key];
    //                 }
    //             }
    //         }
    //     } else {
    //         for(const key in errors.columnOverrides){
    //             delete errors.columnOverrides[key]
    //         }
    //     }
    //     return errors;
    // };

    return <Form schema={columnOverridesSchema} uiSchema={columnOverridesUiSchema} validator={validator}
         formData={formData}
         onChange={onFormChange}
         onSubmit={handleSubmit}
         // customValidate={validate}
    />
}