import React, {useState} from "react";
import {Button, Header, Segment} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {useCompanyId, usePlatformUrls} from "../../core";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {createEsgPortfolio} from "./ESG/esg.client";
import {Loading} from "../../ui/Loading";
import {usePopup} from "../../ui/popup/Popup";
import {useMessages} from "../../ui/MessagesProvider";
import {ErrorPopup} from "../../ui/popup/ErrorPopup";
import {ContentPopup} from "../../ui/popup/ContentPopup";
import {renderLogsContainer} from "../../ui/ProcessLogs";
import {useInstantUpdater} from "../../generic.hooks";
import {GoBackButton} from "../../ui/GoBackButton";
import {useAMCompanyHome} from "../config";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar"
import {clearLineItemsStoreCache} from "../../lineitems-store/RestLineItemsStore.persistence";
const {Grid} = require("semantic-ui-react");

export function ESGProjectTemplate() {
  let companyId = useCompanyId();
  const companyHome = useAMCompanyHome();
  const history = useHistory();
  const { amProjectDetails } = usePlatformUrls();
  let {clear, error} = useMessages();
  let {openPopup, closePopup}   = usePopup();
  let [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileUploaderReRenderer, updateFileUploaderReRenderer] = useInstantUpdater()
  const [processing, setProcessing] = useState<boolean>(false);
  const [uploadSuccessfull, setUploadSuccessfull] = useState<boolean>(false);

  const fileUploadCallback = async (file?: File ) => {
    clear();
    if(!file) return;
    setProcessing(true);
    createEsgPortfolio(companyId, file, (progress) => setUploadProgress(progress))
   .then((res) => {
      setProcessing(false);
      showMappingErrors({...res}, openPopup, closePopup, updateFileUploaderReRenderer, continueToProject);
      if(!('errors' in res && res.errors.length>0) && res.warnings.length===0){
        clearLineItemsStoreCache();
        setUploadSuccessfull(true);
      }
    })
    .catch((err) => {
      setProcessing(false);
      updateFileUploaderReRenderer();
      console.error(err);
      openPopup(<ErrorPopup e={err} header='Failed to create portfolio' onClose={closePopup} />);
    });
  }

  const continueToProject = () => {
    history.push(companyHome);
  }

  return (<>
        {processing && <Loading  />}
        <Segment style={{padding: "40px"}}>
        <div>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <GoBackButton />
        </div>
          {/*<Header as="h2">{"CREATE"}</Header>*/}
          <Grid>
            <Grid.Row columns={1} >
              <h3>Upload Your Data</h3>
              <Grid.Column >
                    <PsFileUploader
                    uploadProgress={uploadProgress}
                    setFile={fileUploadCallback}
                    updater={fileUploaderReRenderer}
                    /> 
              </Grid.Column>

            </Grid.Row>
          </Grid>

        </div>
          <SuccessfulUploadSnackbar show={uploadSuccessfull} onClose={()=>{
            history.push(`/ps/${companyId}/am/dashboard/impact-summary`)
          }} />
        </Segment>
  </>
  );
}

// @TODO: This is a quick extraction from showMappingErrors. Reduce duplicate code down the line.
export const ProjectVersionMappingLogs = ({messages} :{messages:{errors?: string[], warnings: string[]}})=>{
  const {errors: mappingErrors, warnings: mappingWarnings} = messages;
  const hasErrors = (mappingErrors?? []).length > 0;
  const hasWarnings = mappingWarnings.length > 0;
  if(hasErrors || hasWarnings){
    return <Grid>
        <Grid.Row columns={hasErrors ? 2:1}>
          {hasErrors && <Grid.Column width={8}>
            {renderLogsContainer(mappingErrors ?? [], 'error', '400px')}
          </Grid.Column>}
          <Grid.Column width={hasErrors ? 8: 16}>
            {renderLogsContainer(mappingWarnings, 'warning', '400px')}
          </Grid.Column>
        </Grid.Row>
      </Grid>
  }
  return <></>
}

export const showMappingErrors = (
  messages: { errors?: string[]; warnings: string[] },
  openPopup: (element: JSX.Element) => void,
  closePopup: () => void,
  updateFileUploaderReRenderer?: () => void,
  continueToProject?: () => void
) => {
  const { errors: mappingErrors, warnings: mappingWarnings } = messages;
  const hasErrors = (mappingErrors ?? []).length > 0;
  const hasWarnings = mappingWarnings.length > 0;
  const popUpContentHeader = hasErrors
    ? "Version Creation Failed because the following problems were encountered while parsing the supplied excel"
    : "The version was created but with the following warnings";
  if (hasErrors && updateFileUploaderReRenderer) {
    updateFileUploaderReRenderer();
  }
  if (hasErrors || hasWarnings) {
    openPopup(
      <ContentPopup
        header={""}
        size={"small"}
        onClose={closePopup}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Header size="small">{popUpContentHeader}</Header>
        <>
          <ProjectVersionMappingLogs messages={messages} />
          {hasErrors && (
            <>
              <Button onClick={() => closePopup()}>Re-Upload</Button>
            </>
          )}
          {continueToProject && <Button
            floated={hasErrors ? "right" : "left"}
            onClick={() => continueToProject()}
          >
            Go Back To Project
          </Button>}
        </>
      </ContentPopup>
    );
  }
}

const SuccessfulUploadSnackbar = ({show, onClose}: {show:boolean, onClose: ()=>void}) => {
 return <Snackbar open={show} autoHideDuration={6000} onClose={onClose}>
    <Alert
        onClose={onClose}
        severity="success"
        variant="filled"
        sx={{ width: '100%' }}
    >Uploaded Successfully! You will be automatically redirected from this page.
    </Alert>
  </Snackbar>
}