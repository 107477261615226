import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import "./Widgets"
import React, {ReactNode} from "react";
import {Menu} from "semantic-ui-react";
import {Route} from "react-router-dom";
import {NewAuthWrapper} from "../../../auth";
import {Layout} from "../../layout";
import {DealRoom} from "./DealRoom";


registerModule('Twynam', new class extends DefaultModule {

    getRoutes(): React.ReactNode {
        return <>
            <Route exact path='/ps/:companyId/ext/deal-room'
                   render={ () => <NewAuthWrapper>
                       <Layout >
                           <DealRoom />
                       </Layout>
                   </NewAuthWrapper> } />
        </>
    }

    overrideMainMenu(path: string, mainMenu:  { key: string, menuItem: ReactNode }[], companyId: string, history: any) {
        //Insert a new menu item before the Projects menu item
        let newMainMenu = [...mainMenu];

        let projectsIndex = newMainMenu.findIndex((item) => {
            return item.key === 'projects';
        });

        newMainMenu.splice(projectsIndex+1, 0, {
            key: 'deal-room',
            menuItem: (
                <Menu.Item
                    key="deal-room"
                    active={path === 'deal-room'}
                    onClick={() => history.push(`/ps/${companyId}/ext/deal-room`)}
                >
                    Deal Room
                </Menu.Item>
            )
        });

        return newMainMenu;
    }

})