import {formatDistance} from "date-fns";
import {NavLink, useHistory} from "react-router-dom";
import {PS_URLS, useCompany, useCompanyId, useCurrentUser, usePlatformUrls} from "../../core";
import {AmProject, AmVersion, ProjectTemplateConfig} from "../../ps-types";
import {DeleteButton} from "../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {
  addLineItemsFromExcel,
  deleteAmProjectVersion,
  getAmProject,
  getDownloadUrl,
  updateDefaultVersion
} from "./amProject.client";
import React, {useEffect, useState} from "react";
import {LoadingBlock} from "../../ui/Loading";
import {Button, Header, Menu, Message, Radio, Tab} from "semantic-ui-react";
import {getAmProjectConfig} from "../../ps-models";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {useMessages} from "../../ui/MessagesProvider";
import {CompanyTemplates} from "./CompanyTemplates";
import {StorePreview} from "./ProjectStorePreview";
import {ICP4Onboarding} from "../icp4/icp4Onboarding";


export function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

export function ProjectLink({project: p}: {project: AmProject}) {
  const { amProjectNewVersion, fileValidation } = usePlatformUrls();

  return p.fileProcessedAt ?
    <NavLink to={() => fileValidation(p.id)}>{p.name}</NavLink>:
    <NavLink to={() => amProjectNewVersion(p.id)}>{p.name}</NavLink>
}

function DeleteBtn({entityName, entity, handleDelete}: {entityName: string, entity: any, handleDelete: (entity: any) => void}) {
  const content =`Are you sure you want to delete the ${entityName} '${entity["title"]}'?`;

  return <DeleteButton
    content={content}
    onDelete={() => handleDelete(entity)}
  />
}


export function ProjectDetailPage({projectId}: {projectId: string}) {

  let [project, setProject] = useState<AmProject | undefined>();
  let [message, setMessage] = useState<{ level: "info" | "error", message: string }>({level: "info", message: ""});
  const history = useHistory();
  const companyId = useCompanyId();
  const company = useCompany();

  useEffect(() => {
    getAmProject(companyId, projectId).then(setProject);
  }, [companyId, projectId]);

  if(!project) {
    return <LoadingBlock />
  }

  const handleDefaultVersionUpdate = (versionId: string) => {

    if(versionId === project?.defaultVersion) {
      return;
    }

    setProject({...project!, defaultVersion: versionId});
    updateDefaultVersion(companyId, projectId, versionId)
      .then(() => setMessage({level: "info", message: "Default version updated"}))
      .catch(() => setMessage({level: "error", message: "Failed to update default version"}))
  }

  const downloadVersion = async (versionData: AmVersion & { id: string }) => {
    const { id: versionId, createdAt, title } = versionData;
    const { namespace } = getAmProjectConfig(company);
    getDownloadUrl(companyId, projectId, versionId)
      .then(async (res) => {
        if (namespace === "KingEnergy") {
          return await downloadFileForKE(res.url, project?.name ?? "", createdAt, title);
        }
        window.open(res.url, "_blank")
      });
  }


  const getEntities = () => getAmProject(companyId, projectId).then(p =>
    p.versions.map(v => ({...v,
      id: v.versionId,
      hasFile: v.fileUri !== null,
      isDefault: v.versionId === p.defaultVersion}))
      .sort((a, b) => b.createdAt - a.createdAt )
  );

  const deleteEntity = (data: any) => deleteAmProjectVersion(companyId, projectId, data.versionId);

  let def = buildEntityDef(
    {
      entityName: "Versions",
      title: "Versions",
      getEntities,
      deleteEntity,
      table: {
        deleteButton: (props)=> (<DeleteBtn {...props}/>),
        selectedRow: project?.defaultVersion
      },
    },
    {
      title: {
        title: "Version"
      },
      description: {
        title: "Description",
      },
      createdAt: {
        title: "CREATED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      },
      downloadUrl: {
        title: "Download",
        table: {
          render: (value:any, entity) =>
            entity.hasFile && <Button size="mini" onClick={()=>downloadVersion(entity)}> Download </Button>
        }
      },
      isDefault: {
        title: "Default Version",
        table: {
          render: (value:any, entity) => <Radio
            name='defaultVersion'
            checked={ project?.defaultVersion === entity.id }
            onChange={(e, data) => {
            if(data.checked) {
              handleDefaultVersionUpdate(entity.id);
            }
          }} />
        }
      }
    }
  );

  let panes= [
    {
      menuItem: 'Versions', render: () => <Tab.Pane>
        <Button primary
                floated={"right"}
                style={{marginBottom: "1em"}}
                size={"small"}
                onClick={() => history.push(PS_URLS(companyId).amProjectNewVersion(projectId))}
        >Create new version</Button>

        <AddLineItems project={project} versionId={project.defaultVersion!} />


        <EntityCrud entityDef={def} />
      </Tab.Pane>
    },
    {
      menuItem: 'Preview', render: () => <Tab.Pane>
        {project && project?.defaultVersion && <StorePreview project={project} />}
      </Tab.Pane>
    },
    //TODO: WP, needs testing before enabling
    // {
    //   menuItem: 'Add Line Items', render: () => <Tab.Pane>
    //     {project && project?.defaultVersion && <AddLineItems
    //         project={project}
    //         versionId={project.defaultVersion} />}
    //   </Tab.Pane>
    // }
  ]

  if(company.amProjectConfig?.namespace === "ICP4"){

    let projectsIndex = panes.findIndex((item) => {
      return item.menuItem === 'Versions';
    });

    panes.splice(projectsIndex+1, 0,     {
      menuItem: 'Link Solar Devices',
      render: () => <Tab.Pane>
        <ICP4Onboarding projectId={projectId} />
      </Tab.Pane>
    });
  }

  return <div>
    <Header floated="left" as="h3">Project {project.name}</Header>

    {!project && <LoadingBlock />}

    {message.message && <Message info={message.level==="info"} warning={message.level==="error"} >
      {message.message}
    </Message>}

    <Tab panes={panes} />
  </div>


  async function downloadFileForKE(url:string, projectName: string, createdAt: number, title: string) {
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB').replace(/\//g, '.');
    const fileName = `CG Base Model - ${projectName}_${title}_${formattedDate}`;
    try {
      const a = new URL(url);
      const ext = a.pathname.split('/')[2].split('.')[1];
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName + `.${ext}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (e) {
      console.error('Download failed', e);
    }
  }
}

function AddLineItems({ project, versionId } : { project: AmProject, versionId: string }) {

  let companyId = useCompanyId();
  let projectId = project.id;
  const {info} = useMessages();

  const [templateConfigName, setTemplateConfigName] = useState<string>("default");

  const fileUploadCallback = (file?: File) => {
      if(!file) {
        return;
      }
      addLineItemsFromExcel(
      companyId,
      projectId,
      versionId,
      templateConfigName,
      file
    )
  }

  const handleTemplateSelect = (config: ProjectTemplateConfig) => {
    setTemplateConfigName(config.configName)
  }

  return <div>
    <PsFileUploader
      setFile={fileUploadCallback}
    />
  </div>
}





